import ExternalLink from './ExternalLink'

function HarvestLink ({ customerID }) {
  if (!customerID) return null
  return (
    <ExternalLink href={`https://chainio.harvestapp.com/reports?client_id=${customerID}&kind=all_time&tab=projects&active_projects=true`} target='blank' rel='noreferrer'>{customerID}</ExternalLink>
  )
}

export default HarvestLink
