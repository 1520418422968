import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import { Menubar } from 'primereact/menubar'
import { Button } from 'primereact/button'
import isProduction from '../utils/isProduction'
import './StandardLayout.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt, faBuilding, faCircleUser, faCity, faFileExcel, faFileInvoiceDollar, faGaugeHigh, faPlugCircleBolt, faShieldHalved, faSliders, faTableList, faUserShield, faUsers, faUsersCog } from '@fortawesome/free-solid-svg-icons'

function RedBar () {
  if (isProduction()) return null
  return (
    <div className='red-bar' />
  )
}

function StandardLayout () {
  const { logout } = useAuth0()
  const navigate = useNavigate()

  const menuItems = [
    {
      label: 'Flow',
      items: [
        {
          label: 'Workspaces',
          visible: true,
          command: () => navigate('/workspaces'),
          icon: <FontAwesomeIcon icon={faCity} fixedWidth className='mr-1' />
        },
        {
          label: 'Integrations',
          command: () => navigate('/integrations'),
          icon: <FontAwesomeIcon icon={faBuilding} fixedWidth className='mr-1' />
        },
        {
          label: 'Flows',
          command: () => navigate('/flows'),
          icon: <FontAwesomeIcon icon={faBolt} fixedWidth className='mr-1' />
        },
        {
          label: 'Users',
          command: () => navigate('/users'),
          icon: <FontAwesomeIcon icon={faUsers} fixedWidth className='mr-1' />
        },
        {
          label: 'SFTP Users',
          command: () => navigate('/sftp_users'),
          icon: <FontAwesomeIcon icon={faUsersCog} fixedWidth className='mr-1' />
        },
        {
          label: 'Open Connect Adapters',
          command: () => navigate('/adapters'),
          icon: <FontAwesomeIcon icon={faPlugCircleBolt} fixedWidth className='mr-1' />
        },
        {
          label: 'Flow Usage',
          command: () => navigate('/flow_usage_reports'),
          icon: <FontAwesomeIcon icon={faTableList} fixedWidth className='mr-1' />
        }
      ]
    },
    {
      label: 'Embedded',
      items: [
        {
          label: 'Clients',
          command: () => navigate('/embedded_clients'),
          icon: <FontAwesomeIcon icon={faUserShield} fixedWidth className='mr-1' />
        },
        {
          label: 'Executions',
          command: () => navigate('/embedded_executions'),
          icon: <FontAwesomeIcon icon={faTableList} fixedWidth className='mr-1' />
        }
      ]
    },
    {
      label: 'Customers',
      items: [
        {
          label: 'Customers',
          command: () => navigate('/customers'),
          icon: <FontAwesomeIcon icon={faCircleUser} fixedWidth className='mr-1' />
        },
        {
          label: 'Account Review',
          command: () => navigate('/customers/accountreview'),
          icon: <FontAwesomeIcon icon={faGaugeHigh} fixedWidth className='mr-1' />
        }
      ]
    },
    {
      label: 'Tools',
      items: [
        {
          label: 'Flow Layout Builder',
          command: () => navigate('/flow_layout'),
          icon: <FontAwesomeIcon icon={faSliders} fixedWidth className='mr-1' />
        },
        {
          label: 'GraphQL Token',
          command: () => navigate('/graphql'),
          icon: <FontAwesomeIcon icon={faShieldHalved} fixedWidth className='mr-1' />
        },
        {
          label: 'License Types',
          command: () => navigate('/license_types'),
          icon: <FontAwesomeIcon icon={faFileInvoiceDollar} fixedWidth className='mr-1' />
        },
        {
          label: 'XML Validator',
          command: () => navigate('/xml_validator'),
          icon: <FontAwesomeIcon icon={faFileExcel} fixedWidth className='mr-1' />
        }
      ]
    },
    {
      label: 'Links',
      command: () => navigate('/links')
    }
  ]

  useEffect(() => {
    document.title = isProduction() ? 'Admin Portal' : 'RED - Admin Portal'
  }, [])

  return (
    <>
      <RedBar />
      <div className='main-nav'>
        <Menubar
          start={
            <Link to='/'>
              <img
                src='/images/logo130x30.png'
                title='Chain.io Logo'
                height='15px'
              />
            </Link>
          }
          model={menuItems}
          end={
            <Button
              text
              icon='pi pi-sign-out'
              onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
              className='logout-button'
            />
          }
        />
      </div>
      <div className='p-2'>
        <Outlet />
      </div>
    </>
  )
}

export default StandardLayout
