import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { useEffect, useRef, useState } from 'react'

function buildLegacyIntegrations (data, integrationLicenses, makeFlowCountWarnings) {
  const { flows, license } = data
  const selectedLicense = integrationLicenses.find(il => il.sku === license.sku)
  const integrations = [
    {
      flows,
      selectedLicense,
      id: new Date().getTime().toString(),
      flowCountWarnings: makeFlowCountWarnings(flows, selectedLicense)
    }
  ]
  return integrations
}
function buildIntegrations (data, integrationLicenses, makeFlowCountWarnings) {
  if (data.flows) return buildLegacyIntegrations(data, integrationLicenses, makeFlowCountWarnings)
  // default the integration name for each integration for backwards compatibility
  data.integrations.forEach(integration => {
    if (!integration.name) {
      integration.name = integrationLicenses.find(il => il.sku === integration.selectedLicense?.sku)?.name || 'Integration'
    }
  })
  return data.integrations
}

function UploadJSON ({ integrationLicenses, makeFlowCountWarnings, setIntegrations }) {
  const [show, setShow] = useState(false)
  const toast = useRef(null)
  const [fileContent, setFileContent] = useState(null)

  const handleFile = (e) => {
    const reader = new FileReader()
    reader.onload = async (e) => {
      setFileContent(e.target.result)
    }
    reader.readAsText(e.target.files[0])
  }

  useEffect(() => {
    if (fileContent) {
      try {
        const json = JSON.parse(fileContent)
        const integrations = buildIntegrations(json, integrationLicenses, makeFlowCountWarnings)
        setIntegrations(integrations)
        setShow(false)
      } catch (err) {
        console.error(err)
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Invalid JSON file' })
      }
      setFileContent(null)
    }
  }, [fileContent])

  return (
    <>
      <Toast ref={toast} />
      <Button
        onClick={() => setShow(true)}
        label='Load File'
        icon='pi pi-upload'
      />
      {show && (
        <Dialog
          header='Load File'
          visible={show}
          onHide={() => setShow(false)}
        >
          <input
            type='file'
            onChange={handleFile}
          />
        </Dialog>
      )}
    </>
  )
}

export default UploadJSON
