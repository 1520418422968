import portalUrl from '../utils/portalUrl'
import ExternalLink from './ExternalLink'

function WorkspacePortalLink ({ companyUUID, name }) {
  return (
    <ExternalLink href={portalUrl(`/c/${companyUUID}`)}>
      {name}
    </ExternalLink>
  )
}

export default WorkspacePortalLink
