import React, { useEffect } from 'react'
import _ from 'lodash'
import { gql, useQuery, useLazyQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import ApolloErrorRenderer from '../../components/ApolloErrorRenderer'
import Loading from '../../components/Loading'
import NewReportButton from './NewReportButton'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button'

const GET_REPORTS = gql`query getFlowUsageReports {
  flowUsageReports {
    execution_id
    status
    workspace {
      company_uuid
      company_name
    }
    start_date
    end_date
    created_at
  }
}`

const GET_RESULT = gql`query getFlowUsageReport($execution_id: ID!)  {
  flowUsageReport(execution_id: $execution_id) {
    execution_id
    result
  }
}`

function ReportFileDownloadLink ({ report }) {
  const [getDownloadUrl, { loading, error, data }] = useLazyQuery(GET_RESULT)
  useEffect(() => {
    if (data?.flowUsageReport?.result) {
      window.location.href = data?.flowUsageReport?.result
    }
  }, [data])

  if (report.status !== 'SUCCEEDED') return (<div><FontAwesomeIcon icon={faArrowsRotate} spin /></div>)
  if (error) return <ApolloErrorRenderer error={error} />
  if (loading) return <p>Preparing Download...</p>

  const doDownload = async () => {
    getDownloadUrl({ variables: { execution_id: report.execution_id } })
  }

  return (
    <Button onClick={doDownload} download={`${report.execution_id}.csv`}>
      Download CSV
    </Button>
  )
}

function ReportList ({ data }) {
  const flowReportList = data || []

  return (
    <DataTable
      value={flowReportList}
      stripedRows
      rowHover
      resizableColumns
      size='small'
      paginator
      rows={50}
      sortField='created_at'
      sortOrder={-1}
      className='mt-3'
    >
      <Column sortable filter header='Workspace' field='workspace.company_name' body={a => <Link to={`/workspaces/${a.workspace.company_uuid}`}>{a.workspace.company_name}</Link>} />
      <Column sortable filter header='Start Date' field='start_date' />
      <Column sortable filter header='End Date' field='end_date' />
      <Column sortable filter header='Status' field='status' />
      <Column sortable filter header='Created' field='created_at' />
      <Column body={a => <ReportFileDownloadLink report={a} />} />
    </DataTable>
  )
}

function List () {
  const { loading, error, data, refetch, startPolling, stopPolling } = useQuery(GET_REPORTS)

  useEffect(() => {
    if (_.some(data?.flowUsageReports, { status: 'QUEUED' })) {
      startPolling(10000)
    } else {
      stopPolling()
    }
  }, [data])

  if (error) return <ApolloErrorRenderer error={error} />
  if (loading || !data) return <Loading fullPage />

  return (
    <>
      <h1>Flow Usage Reports</h1>
      <NewReportButton refetch={refetch} />
      <ReportList data={data.flowUsageReports} />
    </>
  )
}

export default List
