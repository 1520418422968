import { gql, useQuery } from '@apollo/client'
import ApolloErrorRenderer from '../../components/ApolloErrorRenderer'
import Loading from '../../components/Loading'
import _ from 'lodash'
import DateValue from '../../components/DateValue'
import { Link } from 'react-router-dom'
import CSVDownload from '../../components/CSVDownload'
import { DataTable } from 'primereact/datatable'
import CopyableUUID from '../../components/CopyableUUID'
import { Column } from 'primereact/column'
import { Card } from 'primereact/card'

const CSV_HEADINGS = [
  'Display Name',
  'Adapter ID',
  'Version ID',
  'Registration Status',
  'Adapter Type',
  'Created At',
  'Updated At',
  'Workspace Name',
  'Workspace UUID',
  'Associated Workspace UUIDs',
  'Support URL',
  'Icon URL',
  'Publication Type',
  'Webhook URL',
  'Integration Identifiers'

]

function csvRow (adapter) {
  return [
    adapter.display_name,
    adapter.adapter_id,
    adapter.version_id,
    adapter.registration_status,
    adapter.adapter_type,
    adapter.created_at,
    adapter.updated_at,
    adapter.workspace.company_name,
    adapter.workspace.company_uuid,
    adapter.associated_workspaces.map(w => w.company_uuid).join(','),
    adapter.support_url,
    adapter.adapter_icon_url,
    adapter.publication_type,
    adapter.webhook_url,
    adapter.integration_identifiers.join(',')
  ]
}

const GET_ADAPTERS = gql`query openConnectAdapters {
  openConnectAdapters{
    adapter_id
    version_id
    display_name
    display_description
    support_url
    adapter_type
    registration_status
    integration_identifiers
    webhook_url
    publication_type
    adapter_icon_url
    denial_messages
    created_at
    updated_at
    workspace {
      company_uuid
      company_name
    }
    associated_workspaces {
      company_uuid
      company_name
    }
  }
}`

function SummaryPanel ({ adapters }) {
  const needsReview = adapters.filter(a => a.reviewStatus === 'Under Review')
  const panels = [
    {
      title: 'Needs Review',
      value: needsReview.length
    }
  ]
  return (
    <div className='flex align-items-stretch justify-content-start my-4'>
      {panels.map((panel, idx) => (
        <Card
          key={idx}
          className='mr-2'
          title={panel.title}
          subTitle={panel.subTitle || <>&nbsp;</>}
          style={{ width: '25rem' }}
        >
          <h1>{panel.value}</h1>
        </Card>
      ))}
    </div>
  )
}

function reviewStatus (adapter) {
  if (adapter.unapprovedAdapter) {
    return 'Under Review'
  }
  if (adapter.deniedAdapter) {
    return 'Denied'
  }
  return null
}

function AdapterList ({ adapters }) {
  return (
    <>
      <CSVDownload
        fileName='adapters.csv'
        headings={CSV_HEADINGS}
        recordToStringArrayFn={csvRow}
        records={adapters}
      />
      <DataTable
        value={adapters}
        stripedRows
        rowHover
        resizableColumns
        size='small'
        paginator
        rows={50}
        sortField='display_name'
        sortOrder={1}
      >
        <Column sortable filter header='Display Name' field='display_name' body={a => <Link to={a.adapter_id}>{a.display_name}</Link>} />
        <Column sortable filter header='Workspace' field='workspace.company_name' body={a => <Link to={`/workspaces/${a.workspace.company_uuid}`}>{a.workspace.company_name}</Link>} />
        <Column sortable filter header='Adapter ID' field='adapter_id' body={a => <CopyableUUID uuid={a.adapter_id} />} />
        <Column sortable filter header='Version ID' field='version_id' body={a => <CopyableUUID uuid={a.version_id} />} />
        <Column sortable filter header='Type' field='adapter_type' />
        <Column sortable filter header='Created At' field='created_at' body={a => <DateValue date={a.created_at} />} />
        <Column sortable filter header='Updated At' field='updated_at' body={a => <DateValue date={a.updated_at} />} />
        <Column sortable filter header='Has Approved Version' field='approvedAdapter' body={a => a.approvedAdapter ? 'yes' : 'no'} />
        <Column sortable filter header='Pending Version' field='reviewStatus' />
      </DataTable>
    </>
  )
}

function processAdapters (rawAdapters) {
  const adaptersById = _.groupBy(rawAdapters, 'adapter_id')
  const adapters = _.map(adaptersById, (adaptersForId) => {
    const unapprovedAdapter = _.last(_.sortBy(adaptersForId.filter(a => a.registration_status === 'under_review'), 'version_id'))
    const approvedAdapter = _.last(_.sortBy(adaptersForId.filter(a => a.registration_status === 'approved'), 'version_id'))
    const deniedAdapter = _.last(_.sortBy(adaptersForId.filter(a => a.registration_status === 'denied'), 'version_id'))
    return {
      ...(approvedAdapter || unapprovedAdapter || deniedAdapter),
      unapprovedAdapter,
      approvedAdapter,
      deniedAdapter
    }
  }).map(a => ({ ...a, reviewStatus: reviewStatus(a) }))
  return adapters
}

function Adapters () {
  const { loading, error, data } = useQuery(GET_ADAPTERS)

  if (error) return <ApolloErrorRenderer error={error} />

  if (loading || !data) return <Loading fullPage />

  const processedAdapters = processAdapters(data.openConnectAdapters)

  return (
    <>
      <h1>Adapters</h1>
      <SummaryPanel adapters={processedAdapters} />
      <AdapterList adapters={processedAdapters} />
    </>
  )
}

export default Adapters
