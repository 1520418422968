import { gql, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import ApolloErrorRenderer from '../components/ApolloErrorRenderer'
import Loading from '../components/Loading'
import _ from 'lodash'
import Swal from 'sweetalert2'
import LicenseSelector from '../components/FlowLayout/LicenseSelector'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { ButtonGroup } from 'primereact/buttongroup'
import IntegrationPane from '../components/FlowLayout/IntegrationPane'
import { TabPanel, TabView } from 'primereact/tabview'
import IntegrationSetupChecklist from '../components/FlowLayout/IntegrationSetupChecklist'
import UploadJSON from '../components/FlowLayout/UploadJSON'

const GET_LICENSE_TYPES = gql`query getLicenseTypes {
  licenseDefinitions {
    workspaceLicenseTypes {
      sku
      name
      description
      price
      info_url
      allowed_integration_license_types {
        sku
        name
      }
    }
    integrationLicenseTypes {
      sku
      name
      description
      price
      allowed_licenses
      transaction_count
      info_url
      hidden
      line_icon
      flows_allowed {
        flow_type
        name
        description
        source_file_types {
          label
          code
          description
          is_public
        }
        destination_file_types {
          label
          code
          description
          is_public
        }
        max_flows
      }
    }
  }
}`

const makeFlowCountWarnings = (flows, license) => {
  const maxFlows = {}
  if (license) {
    license.flows_allowed.forEach(fa => {
      maxFlows[fa.name] = fa.max_flows
    })
  }
  if (!flows || flows.length === 0) return []
  const flowCounts = _.countBy(flows, 'flow_type')
  const flowWarnings = Object.keys(flowCounts).map(flowType => {
    if (flowCounts[flowType] > maxFlows[flowType]) {
      return {
        name: flowType,
        count: flowCounts[flowType],
        max: maxFlows[flowType]
      }
    }
    return null
  }).filter(fw => fw !== null)
  return flowWarnings
}

function AddIntegration ({ licenses, addIntegration }) {
  const [show, setShow] = useState(false)

  const selectLicense = (license) => {
    addIntegration(license)
    setShow(false)
  }

  return (
    <>
      <Button onClick={() => setShow(true)} label='Add Integration' className='mx-1' icon='pi pi-plus' />
      <Dialog
        onHide={() => setShow(false)}
        visible={show}
      >
        <LicenseSelector licenses={licenses} selectLicense={selectLicense} />
      </Dialog>
    </>
  )
}

function FlowLayout () {
  const [integrations, setIntegrations] = useState([])
  const [integrationLicenses, setIntegrationLicenses] = useState(null)
  const [activeTab, setActiveTab] = useState(0)

  const { data, loading, error } = useQuery(GET_LICENSE_TYPES)

  const addIntegration = (selectedLicense, flows = []) => {
    const id = new Date().getTime()
    const newIntegrations = [...integrations]
    newIntegrations.unshift({ id, flows, selectedLicense, flowCountWarnings: [], name: selectedLicense.name })
    setIntegrations(newIntegrations)
    setActiveTab(-1)
    setActiveTab(1)
  }

  const setFlows = (id, newFlows) => {
    const newIntegrations = [...integrations]
    const idxToReplace = newIntegrations.findIndex(fg => fg.id === id)
    if (idxToReplace === -1) return
    const integration = newIntegrations[idxToReplace]
    newIntegrations[idxToReplace] = { ...integration, flows: newFlows, flowCountWarnings: makeFlowCountWarnings(newFlows, integration.selectedLicense) }
    setIntegrations(newIntegrations)
  }

  const setIntegrationName = (id, name) => {
    const newIntegrations = [...integrations]
    const idxToReplace = newIntegrations.findIndex(fg => fg.id === id)
    if (idxToReplace === -1) return
    const integration = newIntegrations[idxToReplace]
    newIntegrations[idxToReplace] = { ...integration, name }
    setIntegrations(newIntegrations)
  }

  const removeIntegration = (id) => {
    const newIntegrations = integrations.filter(fg => fg.id !== id)
    setIntegrations(newIntegrations)
  }

  const doRemoveIntegration = (index) => {
    const target = integrations[index - 1]
    if (!target?.id) {
      Swal.fire({
        title: 'Not Closeable',
        text: 'This tab cannot be removed.'
      })
    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: 'This action cannot be undone',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.isConfirmed) {
          removeIntegration(target.id)
        }
      })
    }
    return false // stop the default tab remove behavior
  }

  useEffect(() => {
    if (integrations.length !== 0) {
      setActiveTab(1)
    } else {
      setActiveTab(0)
    }
  }, [integrations?.length])

  useEffect(() => {
    if (data) {
      const { integrationLicenseTypes } = data.licenseDefinitions
      setIntegrationLicenses(integrationLicenseTypes)
    }
  }, [data])

  const doDownload = async () => {
    const { value } = await Swal.fire({
      title: 'Enter File Name',
      input: 'text',
      inputLabel: 'File Name',
      inputPlaceholder: 'flow layout'
    })

    if (!value) return
    const fileName = `${value}.json`

    const jsonData = JSON.stringify({ integrations }, null, 2)
    const blob = new Blob([jsonData], { type: 'application/json' })
    const downloadURL = URL.createObjectURL(blob)
    const element = document.createElement('a')
    element.setAttribute('href', downloadURL)
    element.setAttribute('download', fileName)

    // Append the anchor to the body, click it to initiate download, and then remove it
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  if (error) return <ApolloErrorRenderer error={error} />

  if (loading) return <Loading />

  return (
    <>
      <div className='mb-2'>
        <AddIntegration
          licenses={integrationLicenses}
          addIntegration={addIntegration}
        />
        <ButtonGroup>
          <Button label='Save File' onClick={doDownload} icon='pi pi-download' />
          <UploadJSON
            integrationLicenses={integrationLicenses}
            setIntegrations={setIntegrations}
            makeFlowCountWarnings={makeFlowCountWarnings}
          />
        </ButtonGroup>
      </div>
      <TabView
        activeIndex={activeTab}
        onTabChange={e => setActiveTab(e.index)}
        scrollable
        onBeforeTabClose={e => doRemoveIntegration(e.index)}
      >
        <TabPanel
          header='No Integrations'
          visible={integrations.length === 0}
        >
          <p>Click the "Add Integration" button to get started</p>
          <AddIntegration
            licenses={integrationLicenses}
            addIntegration={addIntegration}
          />
        </TabPanel>
        {integrations.map(integration => (
          <TabPanel
            key={integration.id}
            header={integration.name}
            closable
          >
            <IntegrationPane
              flowGroup={integration}
              setFlows={setFlows}
              integrationLicenses={integrationLicenses}
              doDownload={doDownload}
              setIntegrationName={setIntegrationName}
            />
          </TabPanel>
        ))}
        <TabPanel header='Setup Checklist'>
          <IntegrationSetupChecklist integrations={integrations} />
        </TabPanel>
      </TabView>
    </>
  )
}

export default FlowLayout
