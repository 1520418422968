import { gql, useQuery } from '@apollo/client'
import ApolloErrorRenderer from '../../components/ApolloErrorRenderer'
import Loading from '../../components/Loading'
import { useEffect, useState } from 'react'
import CopyButton from '../../components/CopyButton'
import { Link, useSearchParams } from 'react-router-dom'
import CSVDownload from '../../components/CSVDownload'
import MaxioLink from '../../components/MaxioLink'
import RiskBadge from '../../components/RiskBadge'
import ZendeskOrganizationLink from '../../components/ZendeskOrganizationLink'
import HarvestLink from '../../components/HarvestLink'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Checkbox } from 'primereact/checkbox'

const CSV_HEADINGS = [
  'Name',
  'Workspace Name',
  'Workspace ID',
  'Maxio - Pipedrive ID',
  'Account Name',
  'Account Executive',
  'Client Success Manager',
  'Maxio - Zendesk ID',
  'Maxio ID',
  'Harvest ID',
  'Risk'
]

function csvRow (customer) {
  return [
    customer.name,
    customer.company?.company_name,
    customer.company_uuid,
    customer.pipedrive_id,
    customer.pipedriveOrganization?.name,
    customer.pipedriveOrganization?.owner?.name,
    customer.pipedriveOrganization?.client_success_manager?.name,
    customer.zendesk_id,
    customer.saasoptics_id,
    customer.harvest_id,
    customer.pipedriveOrganization?.risk
  ]
}

const GET_CUSTOMERS = gql`query getCustomers($includeInactive: Boolean) {
  customers(includeInactive: $includeInactive) {
    company {
      company_name
    }
    company_uuid
    email
    name
    saasoptics_id
    pipedrive_id
    zendesk_id
    harvest_id
    projectBudgets {
      budget
      is_active
      budget_remaining
      budget_spent
      budget
    }
    pipedriveOrganization {
      risk
      client_success_manager {
        email
        name
      }
      owner {
        email
        name
      }
    }
  }
}`

const BAR_COLORS = [
  { color: 'bg-success', min: 0, max: 50 },
  { color: 'bg-warning', min: 50, max: 75 },
  { color: 'bg-danger', min: 75 }
]
function ProjectBudgetWidget ({ projectBudgets }) {
  if (!projectBudgets || projectBudgets.length === 0) return null
  const active = projectBudgets.filter(pb => pb.is_active)
  const total = Math.round(active.reduce((pv, cv) => pv + cv.budget, 0))

  if (total === 0) return null

  const spent = Math.round(active.reduce((pv, cv) => pv + cv.budget_spent, 0))
  const percent = Math.round((spent / total) * 100)

  const backgroundColor = BAR_COLORS.find(bc => percent >= bc.min && (!bc.max || percent < bc.max)).color
  return (
    <div title={`${spent} / ${total}`}>
      <div className='progress'>
        <div
          className={`progress-bar ${backgroundColor}`}
          role='progressbar'
          style={{ width: `${percent}%` }}
          aria-valuenow={percent}
          aria-valuemin='0'
          aria-valuemax='100'
        >
          {percent}%
        </div>
      </div>
    </div>
  )
}

function CompanyUUID ({ uuid }) {
  if (!uuid) return null
  return (
    <>
      {uuid} <CopyButton text={uuid} />
    </>
  )
}

function CompanyLink ({ customer }) {
  if (!customer.company) return null
  return (
    <Link to={`/workspaces/${customer.company_uuid}`}>
      {customer.company.company_name}
    </Link>
  )
}

function CustomerLink ({ customer }) {
  return (
    <Link to={`/customers/${customer.saasoptics_id}`}>
      {customer.name}
    </Link>
  )
}

function CustomerList ({ data, includeInactive, changeIncludeInactiveFlag }) {
  const companies = data.customers

  return (
    <>
      <CSVDownload
        fileName='customers.csv'
        headings={CSV_HEADINGS}
        records={companies}
        recordToStringArrayFn={csvRow}
      />
      <div className='my-2'>
        <Checkbox
          checked={includeInactive}
          onChange={evt => changeIncludeInactiveFlag(evt.target.checked)}

        />Include Inactive Customers
      </div>
      <DataTable
        value={companies}
        stripedRows
        rowHover
        resizableColumns
        size='small'
        paginator
        rows={50}
        sortField='name'
        sortOrder={1}
      >
        <Column sortable filter header='Name' field='name' body={a => <CustomerLink customer={a} />} />
        <Column sortable filter header='Workspace Name' field='company.company_name' body={a => <CompanyLink customer={a} />} />
        <Column sortable filter header='Workspace ID' field='company_uuid' body={a => <CompanyUUID uuid={a.company_uuid} />} />
        <Column sortable filter header='Account Executive' field='pipedriveOrganization.owner.name' />
        <Column sortable filter header='Client Success Manager' field='pipedriveOrganization.client_success_manager.name' />
        <Column sortable filter header='Risk' field='pipedriveOrganization.risk' body={a => <RiskBadge risk={a.pipedriveOrganization?.risk} />} />
        <Column filter header='Zendesk Org' field='zendesk_id' body={a => <ZendeskOrganizationLink customerID={a.zendesk_id} />} />
        <Column filter header='Harvest ID' field='harvest_id' body={a => <HarvestLink customerID={a.harvest_id} />} />
        <Column filter header='Maxio ID' field='saasoptics_id' body={a => <MaxioLink customerID={a.saasoptics_id} />} />
        <Column header='Project Budgets' body={a => <ProjectBudgetWidget projectBudgets={a.projectBudgets} />} />
      </DataTable>
    </>
  )
}

function Customers () {
  const [searchParams, setSearchParams] = useSearchParams()
  const [includeInactive, setIncludeInactive] = useState(searchParams.get('includeInactive') === 'true')
  const { loading, error, data, refetch } = useQuery(GET_CUSTOMERS, {
    variables: { includeInactive }
  })

  const changeIncludeInactiveFlag = (newFlag) => {
    setSearchParams({ includeInactive: newFlag ? 'true' : false })
  }

  useEffect(() => {
    const paramState = searchParams.get('includeInactive') === 'true'
    if (paramState !== includeInactive) {
      setIncludeInactive(paramState)
      refetch({ includeInactive })
    }
  }, [searchParams, includeInactive, refetch])

  if (error) return <ApolloErrorRenderer error={error} />

  if (loading || !data) return <Loading fullPage />

  return (
    <>
      <h1>Customers</h1>
      <CustomerList
        data={data}
        includeInactive={includeInactive}
        changeIncludeInactiveFlag={changeIncludeInactiveFlag}
      />
    </>
  )
}

export default Customers
