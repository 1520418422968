import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { useState } from 'react'
import FieldListView from '../FieldListView'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import _ from 'lodash'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import Markdown from 'react-markdown'

function EditFlowPanel ({ license, flow, saveFn, buttonText, maxFlows }) {
  const [showPanel, setShowPanel] = useState(false)
  const [tmpFlow, setTmpFlow] = useState(flow)

  const onShow = () => {
    setTmpFlow(flow)
  }

  const doSave = () => {
    saveFn(tmpFlow)
    setShowPanel(false)
  }

  const updateField = (field, value) => {
    setTmpFlow({
      ...tmpFlow,
      [field]: value
    })
  }

  const selectedFlowType = license.flows_allowed.find(fa => fa.name === tmpFlow.flow_type)
  let sourceFileTypeOptions = []
  let destinationFileTypeOptions = []
  if (selectedFlowType) {
    sourceFileTypeOptions = [
      ..._.orderBy(selectedFlowType.source_file_types, ['label', 'code']).map(sft => ({
        label: sft.label,
        value: sft.label
      })),
      { label: 'New Type', value: 'new' }
    ]
    destinationFileTypeOptions = [
      ..._.orderBy(selectedFlowType.destination_file_types, ['label', 'code']).map(dft => ({
        label: dft.label,
        value: dft.label
      })),
      { label: 'New Type', value: 'new' }
    ]
  }

  const EDIT_FLOW_FIELDS = [
    {
      title: 'Name',
      value: (f) => (
        <InputText
          className='w-full'
          value={f.name}
          onChange={(e) => updateField('name', e.target.value)}
        />
      )
    },
    {
      title: 'Flow Type',
      value: (f) => (
        <Dropdown
          className='w-full'
          value={f.flow_type}
          options={_.orderBy(license.flows_allowed, ['name']).map(fa => ({ label: `${fa.name} (max: ${maxFlows[fa.name]})`, value: fa.name }))}
          onChange={(e) => updateField('flow_type', e.target.value)}
          showClear
          filter
        />
      )
    },
    {
      title: 'Source Protocol',
      value: (f) => (
        <Dropdown
          className='w-full'
          value={f.source_protocol}
          options={[
            'AS2',
            'AWS S3',
            'eAdaptor',
            'Email',
            'HTTPS API',
            'SFTP'
          ]}
          onChange={(e) => updateField('source_protocol', e.target.value)}
          showClear
          filter
        />
      )
    },
    {
      title: 'Source File Type',
      value: (f) => (
        <Dropdown
          className='w-full'
          value={f.source_file_type}
          options={sourceFileTypeOptions}
          onChange={(e) => updateField('source_file_type', e.target.value)}
          disabled={sourceFileTypeOptions.length === 0}
          showClear
          filter
        />
      )
    },
    {
      title: 'Destination File Type',
      value: (f) => (
        <Dropdown
          className='w-full'
          value={f.destination_file_type}
          options={destinationFileTypeOptions}
          onChange={(e) => updateField('destination_file_type', e.target.value)}
          disabled={destinationFileTypeOptions.length === 0}
          showClear
          filter
        />
      )
    },
    {
      title: 'Destination Protocol',
      value: (f) => (
        <Dropdown
          className='w-full'
          value={f.destination_protocol}
          options={[
            'API',
            'AS2',
            'Cargowise REST (preferred)',
            'Cargowise SOAP',
            'eFreight',
            'Geodis',
            'AWS S3',
            'Email',
            'DHL',
            'Melio',
            'MISA AMIS (https://misa.vn)',
            'SEDNA',
            'SFTP',
            'No Action'
          ]}
          onChange={(e) => updateField('destination_protocol', e.target.value)}
          showClear
          filter
        />
      )
    },
    {
      title: 'Notes (supports markdown)',
      value: (f) => (
        <InputTextarea
          className='w-full'
          rows={5}
          value={f.notes}
          onChange={(e) => updateField('notes', e.target.value)}
        />
      )
    }
  ]

  return (
    <>
      <Button
        link
        onClick={() => setShowPanel(true)}
      >
        {buttonText}
      </Button>
      {showPanel && (
        <Dialog
          header={buttonText}
          visible={showPanel}
          onHide={() => setShowPanel(false)}
          onShow={onShow}
        >
          <div>
            <FieldListView
              data={tmpFlow}
              listDefinition={EDIT_FLOW_FIELDS}
            />
          </div>
          <Button onClick={doSave}>Save</Button>
        </Dialog>
      )}
    </>
  )
}

const HIGHLIGHT_CLASSES = 'bg-red-700 text-white w-full h-full p-2 border-round'
function HighlightEmptyFieldTemplate ({ value }) {
  if (!value || value.toString().length === 0) {
    return (
      <div className={HIGHLIGHT_CLASSES}>
        Not Set
      </div>
    )
  }
  if (value.toString() === 'new') {
    return (
      <div className={HIGHLIGHT_CLASSES.replace(/red/, 'orange')}>
        New Type
      </div>
    )
  }
  return value
}

function FlowTable ({ license, flows, setFlows, maxFlows }) {
  const addFlow = (flow) => {
    const toAdd = { ...flow }
    if (!toAdd.flow_id) {
      toAdd.flow_id = new Date().getTime()
    }
    const flowIdx = flows.findIndex(f => f.flow_id === toAdd.flow_id)
    if (flowIdx > -1) {
      const newFlows = [...flows]
      newFlows[flowIdx] = toAdd
      newFlows[flowIdx].flow_id = new Date().getTime() // reset the id to force refresh
      setFlows(newFlows)
    } else {
      setFlows([...flows, toAdd])
    }
  }

  const removeFlow = (flow) => {
    const newFlows = flows.filter(f => f.flow_id !== flow.flow_id)
    setFlows(newFlows)
  }

  if (!license) return null
  return (
    <>
      <DataTable
        value={flows}
        showGridlines
        className='my-2'
        emptyMessage={
          <EditFlowPanel
            license={license}
            flow={
              { name: '', flow_type: '', source_protocol: '', source_file_type: '', destination_file_type: '', destination_protocol: '', notes: '' }
            }
            saveFn={addFlow}
            buttonText='Add Flow'
            maxFlows={maxFlows}
          />
        }
        header={
          <div className='flex flex-row'>
            <div className='flex-grow-1'><strong>{license.name}</strong></div>
            <div>
              <EditFlowPanel
                license={license}
                flow={
                  { name: '', flow_type: '', source_protocol: '', source_file_type: '', destination_file_type: '', destination_protocol: '', notes: '' }
                }
                saveFn={addFlow}
                buttonText='Add Flow'
                maxFlows={maxFlows}
              />
            </div>
          </div>
        }
      >
        <Column
          field='name'
          header='Name'
          body={(f) => <HighlightEmptyFieldTemplate value={f.name} />}
        />
        <Column
          field='flow_type'
          header='Type'
          body={(f) => <HighlightEmptyFieldTemplate value={f.flow_type} />}
        />
        <Column
          field='source_protocol'
          header='Source Protocol'
          body={(f) => <HighlightEmptyFieldTemplate value={f.source_protocol} />}
        />
        <Column
          field='source_file_type'
          header='Source File Type'
          body={(f) => <HighlightEmptyFieldTemplate value={f.source_file_type} />}
        />
        <Column
          field='destination_file_type'
          header='Destination File Type'
          body={(f) => <HighlightEmptyFieldTemplate value={f.destination_file_type} />}
        />
        <Column
          field='destination_protocol'
          header='Destination Protocol'
          body={(f) => <HighlightEmptyFieldTemplate value={f.destination_protocol} />}
        />
        <Column
          field='notes'
          header='Notes'
          body={(f) => <Markdown>{f.notes}</Markdown>}
        />
        <Column
          field='actions'
          header='Actions'
          body={(f) => (
            <>
              <EditFlowPanel
                license={license}
                flow={f}
                saveFn={addFlow}
                buttonText='Edit'
                maxFlows={maxFlows}
              />
              <Button
                link
                onClick={() => removeFlow(f)}
                label='Delete'
              />
            </>
          )}
        />
      </DataTable>
    </>
  )
}

function LayoutPane ({ integration, setSelectedLicense, setFlows, maxFlows, setIntegrationName }) {
  const { flows, selectedLicense, name } = integration
  return (
    <>
      <InputText
        value={name}
        onChange={(e) => setIntegrationName(e.target.value)}
        placeholder='Integration Name'
        className='p-inputtext-lg w-full my-2'
      />
      <FlowTable
        license={selectedLicense}
        setSelectedLicense={setSelectedLicense}
        flows={flows}
        setFlows={setFlows}
        maxFlows={maxFlows}
      />
    </>
  )
}

export default LayoutPane
