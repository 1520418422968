import { ProgressSpinner } from 'primereact/progressspinner'

function Loading ({ fullPage = false }) {
  if (fullPage) {
    return (
      <div className='flex align-items-center justify-content-center h-screen'>
        <ProgressSpinner title='loading' />
      </div>
    )
  }
  return (
    <ProgressSpinner title='loading' />
  )
}

export default Loading
