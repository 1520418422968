import React, { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import ApolloErrorRenderer from '../../components/ApolloErrorRenderer'
import Loading from '../../components/Loading'
import { Link } from 'react-router-dom'
import CSVDownload from '../../components/CSVDownload'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'

const CSV_HEADINGS = [
  'Email',
  'First Name',
  'Last Name'
]

function csvRow (user) {
  return [
    user.email,
    user.first_name,
    user.last_name
  ]
}

const GET_USERS = gql`query getUsers {
  platformUsers {
    email
    first_name
    last_name
  }
}`

function UsersList ({ data }) {
  const [globalFilterValue, setGlobalFilterValue] = useState('')

  const users = data.platformUsers
  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    setGlobalFilterValue(value)
  }
  return (
    <>
      <div className='flex justify-content-between flex-wrap mb-3'>
        <CSVDownload
          fileName='users.csv'
          headings={CSV_HEADINGS}
          recordToStringArrayFn={csvRow}
          records={users}
        />
        <div className='flex justify-content-end'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder='Keyword Search' />
          </span>
        </div>
      </div>
      <DataTable
        value={users}
        stripedRows
        rowHover
        resizableColumns
        size='small'
        paginator
        rows={50}
        sortField='email'
        sortOrder={1}
        className='mt-3'
        globalFilter={globalFilterValue}
        dataKey='email'
        globalFilterFields={['first_name', 'last_name', 'email']}

      >
        <Column
          sortable filter header='Email' field='email'
          body={user => (
            <Link to={`/users/${encodeURIComponent(user.email)}`}>
              {user.email}
            </Link>
          )}
        />
        <Column sortable filter header='First Name' field='first_name' />
        <Column sortable filter header='Last Name' field='last_name' />
      </DataTable>
    </>
  )
}

function List () {
  const { loading, error, data } = useQuery(GET_USERS)

  if (error) return <ApolloErrorRenderer error={error} />

  if (loading || !data) return <Loading fullPage />

  return (
    <>
      <h1>Users</h1>
      <UsersList data={data} />
    </>
  )
}

export default List
