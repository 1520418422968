import { useEffect, useState } from 'react'
import SimpleDashboardWidget from './SimpleDashboardWidget'
import ExternalLink from './ExternalLink'
import { Badge } from 'primereact/badge'
import { contrastBrightness, convert } from 'colvertize'
import portalUrl from '../utils/portalUrl'

const LOWERED_SATURATIONS = {}
function lowerSaturation (base) {
  const lowered = LOWERED_SATURATIONS[base]
  if (lowered) return lowered
  const r = convert(base, 'css-hex', contrastBrightness({ contrast: 0.6 }))
  LOWERED_SATURATIONS[base] = r
  return r
}

const TAG_COLORS = {
  everyone: '#140dda',
  developers: '#FFBF00',
  sales: '#32a858',
  'client success': '#8e32a8',
  accounting: '#a85d32'
}

const APP_LINKS = [
  {
    url: 'https://app.getguru.com/collections/606lx/New-Kid-on-the-Block-Playbook',
    title: 'Guru',
    subtitle: 'Our internal knowledgebase and a great starting point if you\'re new.',
    tags: [
      'everyone'
    ]
  },
  {
    url: portalUrl('/'),
    title: 'Portal',
    subtitle: 'The Chain.io application',
    tags: [
      'everyone'
    ]
  },
  {
    url: 'https://chainio-core.ideas.aha.io/',
    title: 'Ideas',
    subtitle: 'Submit new product ideas here.',
    tags: [
      'everyone'
    ]
  },
  {
    url: 'https://support.chain.io',
    title: 'Support',
    subtitle: 'Customer facing knowledge base and support portal (powered by Zendesk)',
    tags: [
      'everyone'
    ]
  },
  {
    url: 'https://chainio.aha.io/',
    title: 'Aha!',
    subtitle: 'The application where we manage our product, strategy, and coding assignments.',
    tags: [
      'everyone'
    ]
  },
  {
    url: 'https://miro.com/app/board/uXjVN-PYpUc=/',
    title: 'Personas',
    subtitle: 'Our customer personas and their needs on a miro board.  Password: personas',
    tags: [
      'everyone'
    ]
  },
  {
    url: 'https://chainio.bamboohr.com',
    title: 'Bamboo',
    subtitle: 'Our human resources management system. Go here to request time off or to find your pay information.',
    tags: [
      'everyone'
    ]
  },
  {
    url: 'https://j12.saasoptics.com/chainio/',
    title: 'Maxio',
    subtitle: 'Our billing and revenue management platform.',
    tags: [
      'accounting'
    ]
  },
  {
    url: 'https://salesforce.com',
    title: 'Salesforce',
    subtitle: 'Our customer relationship management software.  Use this to see sale opportunities and account details.',
    tags: [
      'sales',
      'client success'
    ]
  },
  {
    url: 'https://github.com/mbsctech',
    title: 'Github',
    subtitle: 'Our organization on Github.',
    tags: [
      'developers'
    ]
  },
  {
    url: 'https://sentry.io/auth/login/',
    title: 'Sentry',
    subtitle: 'Our error tracking and alerting platform.',
    tags: [
      'developers'
    ]
  },
  {
    url: 'https://us-east-1.console.aws.amazon.com/cloudwatch/home?region=us-east-1#dashboards/dashboard/Flow-Metrics',
    title: 'Flow Metrics Dashboard',
    subtitle: 'Displays statistics about flow executions and statuses broken down by customer and flow.',
    tags: [
      'everyone'
    ]
  },
  {
    url: 'https://us-east-1.console.aws.amazon.com/cloudwatch/home?region=us-east-1#dashboards/dashboard/Flow-Internals',
    title: 'Flow Internals Dashboard',
    subtitle: 'Displays statistics about flow backend systems including launchers, errors, throttle queues, logging, and more.',
    tags: [
      'developers'
    ]
  }
]

function AppTag ({ tag, activeTag, setActiveTag }) {
  const isActive = activeTag === tag

  const bgColorBase = TAG_COLORS[tag] || '#0098F0'
  const backgroundColor = isActive ? bgColorBase : lowerSaturation(bgColorBase)

  return (
    <Badge className='mx-1' style={{ cursor: 'pointer', backgroundColor }} onClick={() => setActiveTag(isActive ? null : tag)} value={tag} />
  )
}

function AppTags ({ tags, activeTag, setActiveTag }) {
  const tagList = tags || []
  return (
    <div>
      {tagList.map((tag, idx) => <AppTag tag={tag} key={idx} activeTag={activeTag} setActiveTag={setActiveTag} />)}
    </div>
  )
}

function AppLinkItem ({ link, activeTag, setActiveTag }) {
  return (
    <div className='flex flex-row p-2 hover:surface-300'>
      <div className='flex-grow-1'>
        <ExternalLink href={link.url} target='_blank' rel='noreferrer'>{link.title}</ExternalLink>
        {link.subtitle &&
          <div className='font-light'>{link.subtitle}</div>}
      </div>
      <div className='flex-none'>
        <AppTags tags={link.tags} activeTag={activeTag} setActiveTag={setActiveTag} />
      </div>
    </div>
  )
}

function AppLinks () {
  const [activeTag, setActiveTag] = useState(null)
  const [appLinks, setAppLinks] = useState(APP_LINKS)

  useEffect(() => {
    if (activeTag) {
      setAppLinks(APP_LINKS.filter(link => Array.isArray(link.tags) && link.tags.find(v => activeTag === v)))
    } else {
      setAppLinks(APP_LINKS)
    }
  }, [activeTag])

  return (
    <SimpleDashboardWidget title='Links'>
      {appLinks.map((link, idx) => <AppLinkItem key={idx} link={link} activeTag={activeTag} setActiveTag={setActiveTag} />)}
    </SimpleDashboardWidget>
  )
}

export default AppLinks
