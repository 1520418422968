import { gql, useQuery } from '@apollo/client'
import ApolloErrorRenderer from '../../components/ApolloErrorRenderer'
import Loading from '../../components/Loading'
import { Card } from 'primereact/card'
import { TabMenu } from 'primereact/tabmenu'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useState } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import RiskBadge from '../../components/RiskBadge'
import { ProgressBar } from 'primereact/progressbar'
import PipedriveNotesPane from '../../components/PipedriveNotesPane'

const GET_CUSTOMERS = gql`query getCustomers($includeInactive: Boolean) {
  customers(includeInactive: $includeInactive) {
    company {
      company_name
    }
    saasoptics_id
    company_uuid
    email
    name
    projectBudgets {
      budget
      is_active
      budget_remaining
      budget_spent
      budget
    }
    pipedriveOrganization {
      risk
      client_success_manager {
        email
        name
      }
      owner {
        email
        name
      }
    }
  }
}`

const riskRank = {
  'No Live Integrations': '1',
  Red: '2',
  Yellow: '3',
  Green: '4'
}
const riskSorter = (a, b) => {
  if (riskRank[a] && !riskRank[b]) return 1
  if (riskRank[b] && !riskRank[a]) return -1
  if (!riskRank[a] && !riskRank[b]) return a.localeCompare(b)
  return riskRank[a].localeCompare(riskRank[b])
}

function CompanyLink ({ customer }) {
  if (!customer.company) return null
  return (
    <Link to={`/workspaces/${customer.company_uuid}`}>
      {customer.company.company_name}
    </Link>
  )
}

function CustomerLink ({ customer }) {
  return (
    <Link to={`/customers/${customer.saasoptics_id}`}>
      {customer.name}
    </Link>
  )
}

function RiskGroup ({ companies, isActive }) {
  if (!isActive) return null
  return (
    <>
      <DataTable
        value={companies}
        stripedRows
        rowHover
        resizableColumns
      >
        <Column sortable header='Name' body={customer => CustomerLink({ customer })} />
        <Column sortable header='Workspace' body={customer => CompanyLink({ customer })} />
        <Column sortable field='pipedriveOrganization.owner.name' header='Account Executive' />
        <Column sortable field='pipedriveOrganization.client_success_manager.name' header='Client Success Manager' />
        <Column header='Notes' body={customer => PipedriveNotesPane({ customer })} />
      </DataTable>
    </>
  )
}

function TabTitle ({ risk, companies }) {
  return (
    <>
      <RiskBadge risk={risk.toString()} end={`(${companies.length})`} />
    </>
  )
}

function CustomerList ({ data }) {
  const [activeTab, setActiveTab] = useState('Red')

  const baseVal = _(data.customers).orderBy(['name', 'company.company_name'])

  const companies = baseVal.value()
  const groupedCompanies = _.groupBy(companies, 'pipedriveOrganization.risk')

  return (
    <>
      <TabMenu
        className='mt-4'
        model={Object.keys(groupedCompanies).sort(riskSorter).filter(r => r).map((risk) => ({
          // template: ,
          label: <TabTitle risk={risk} companies={groupedCompanies[risk]} />,
          command: () => setActiveTab(risk)
        }))}
      />
      {Object.keys(groupedCompanies).sort(riskSorter).map((risk) => (
        <RiskGroup
          key={risk}
          isActive={activeTab === risk}
          risk={risk}
          companies={groupedCompanies[risk]}
        />
      ))}
    </>
  )
}

const DISCUSS_LEVELS = [
  'red',
  'yellow',
  'no live integrations'
]

function percent (value, total) {
  return _.round(_.divide(value, total), 2) * 100
}

function SummaryPanel ({ data }) {
  const discuss = data.customers.filter(c => DISCUSS_LEVELS.includes(c.pipedriveOrganization?.risk?.toLowerCase()))
  const panels = [
    {
      title: 'Total Customers',
      value: data.customers.length
    },
    {
      title: 'To Discuss',
      subTitle: <ProgressBar value={percent(discuss.length, data.customers.length)} showValue={false} />,
      value: discuss.length
    }
  ]
  return (
    <div className='flex align-items-stretch justify-content-start my-4'>
      {panels.map((panel, idx) => (
        <Card
          key={idx}
          className='mr-2'
          title={panel.title}
          subTitle={panel.subTitle || <>&nbsp;</>}
          style={{ width: '25rem' }}
        >
          <h1>{panel.value}</h1>
        </Card>
      ))}
    </div>
  )
}

function CustomerReview () {
  const { loading, error, data } = useQuery(GET_CUSTOMERS)

  if (error) return <ApolloErrorRenderer error={error} />

  if (loading || !data) return <Loading fullPage />

  return (
    <div className='p-2'>
      <h1>Account Review</h1>
      <SummaryPanel data={data} />
      <CustomerList
        data={data}
      />
    </div>
  )
}

export default CustomerReview
