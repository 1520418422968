import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faMoneyBills, faPeopleArrows, faPeopleGroup, faRulerCombined } from '@fortawesome/free-solid-svg-icons'
import GoalPanel from '../components/GoalPanel'
import Loading from '../components/Loading'
import { Message } from 'primereact/message'

// Google sheet link: https://docs.google.com/spreadsheets/d/10ClvD3fGPOnIP0j-ZtYtly4evrqexhCx0fETiALFiSY/edit#gid=1632442009
// example code that went into the sheet to make it into an api: https://github.com/onescales/google-sheet-api/
const DATA_URL = 'https://script.google.com/macros/s/AKfycbw_B5y4-HiTy6rHMScE7Q9jmTbkasGYPlN5sMvL4dC_MAsP3gR9A2OSFZKzIr0II_uPmQ/exec'

const ICONS = {
  faLock,
  faMoneyBills,
  faPeopleArrows,
  faPeopleGroup,
  faRulerCombined
}
async function getBoulders () {
  const res = await fetch(`${DATA_URL}?path=Boulders&action=read`)
  const { data } = await res.json()
  return { boulders: data }
}

async function getRocks () {
  const res = await fetch(`${DATA_URL}?path=Rocks&action=read`)
  const { data } = await res.json()
  return { rocks: data }
}

async function getProductThemes () {
  const res = await fetch(`${DATA_URL}?path=Product&action=read`)
  const { data } = await res.json()
  return { productThemes: data }
}

function ProductThemes () {
  const [productThemes, setProductThemes] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    const doWork = async () => {
      try {
        const data = await getProductThemes()
        if (!data.productThemes) {
          console.error('No product themes found', data)
          throw new Error('No product themes found')
        }
        setProductThemes(data.productThemes)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }
    if (productThemes) return
    if (loading) return
    if (error) return
    doWork()
  }, [productThemes, loading, error])

  if (loading || !productThemes) return <Loading />

  if (error) return <Message severity='error' text={error} />

  return (
    <div>
      <div className='text-center'>
        <h1>Product Themes</h1>
        <p>These are the themes we're working on to support our Boulders.</p>
      </div>
      {
        productThemes.map((productTheme, idx) => (
          <GoalPanel
            key={idx}
            title={productTheme.Theme}
            description={productTheme['Short description']}
            longDescription={productTheme['Long description']}
            tags={productTheme['Connected boulder']?.split(',').map((boulder) => boulder.trim()).sort()}
            themeColor='#0070AA'
            fullColor={false}
            className='my-4'
          />
        ))
      }
    </div>
  )
}

function Boulders () {
  const [boulders, setBoulders] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    const doWork = async () => {
      try {
        const data = await getBoulders()
        setBoulders(data.boulders)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }
    if (boulders) return
    if (loading) return
    if (error) return
    doWork()
  }, [boulders, loading, error])

  if (loading || !boulders) return <Loading />

  if (error) return <Message severity='error' text={error} />

  return (
    <div className='mb-3'>
      <div className='text-center'>
        <h1>Our Boulders</h1>
        <p>These are our big initiatves for the year.</p>
        <p>When you're considering whether you're working on the right thing or moving in the right direction, see how well your work aligns with one of these boulders.</p>
      </div>
      <div className='grid'>
        {boulders.map((boulder, idx) => (
          <div className='col col-6 md:col-3' key={idx}>
            <GoalPanel
              title={boulder.Boulder}
              description={boulder['Short description']}
              longDescription={boulder['Long description']}
              icon={boulder.Icon && <FontAwesomeIcon icon={ICONS[boulder.Icon]} />}
              themeColor='#0098F0'
            />
          </div>
        ))}
      </div>
    </div>
  )
}

function Rocks () {
  const [rocks, setRocks] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    const doWork = async () => {
      try {
        const data = await getRocks()
        setRocks(data.rocks)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }
    if (rocks) return
    if (loading) return
    if (error) return
    doWork()
  }, [rocks, loading, error])

  if (loading || !rocks) return <Loading />

  if (error) return <Message severity='error' text={error} />

  return (
    <div>
      <div className='text-center'>
        <h1>Our Rocks</h1>
        <p>These are the things we're working on to support our Boulders.</p>
      </div>
      {
        rocks.map((rock, idx) => (
          <GoalPanel
            key={idx}
            title={rock.Rock}
            description={rock['Short description']}
            longDescription={rock['Long description']}
            tags={rock['Connected boulder']?.split(',').map((boulder) => boulder.trim()).sort()}
            pathToComplete={rock['Path to complete']}
            owner={rock.Stakeholder}
            due={rock['Target dates']}
            themeColor='#21211D'
            fullColor={false}
            className='my-4'
          />
        ))
      }
    </div>
  )
}

function Home () {
  return (
    <div className='md:px-6 sm:px-2'>
      <Boulders />
      <div className='grid'>
        <div className='sm:col md:col-6 sm:pl-2 md:px-8'>
          <Rocks />
        </div>
        <div className='sm:col md:col-6 sm:pl-2 md:px-8'>
          <ProductThemes />
        </div>
      </div>
    </div>
  )
}

export default Home
