import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { useEffect, useRef, useState } from 'react'
import { setContext } from '@apollo/client/link/context'
import isProduction from '../utils/isProduction'
import Loading from './Loading'
import { Outlet } from 'react-router-dom'

const TEST_ENV = import.meta.env.VITE_CHAINIO_ENV === 'test'

const getGraphqlEndpoint = () => {
  if (isProduction()) {
    return 'https://portal-api.chain.io/adminGraphql'
  } else if (TEST_ENV) {
    return 'http://localhost:4000/red/adminGraphql'
  } else {
    return 'https://red-portal-api.chain.io/adminGraphql'
  }
}

const httpLink = createHttpLink({
  uri: getGraphqlEndpoint()
})

function useToken () {
  const { getAccessTokenSilently } = useAuth0()
  const [token, setToken] = useState(null)

  useEffect(() => { getAccessTokenSilently().then(setToken) }, [getAccessTokenSilently])

  return token
}

function ApolloWrapper ({ children }) {
  const token = useToken()

  const clientRef = useRef(null)

  if (token && !clientRef.current) {
    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`
        }
      }
    })

    clientRef.current = new ApolloClient({
      cache: new InMemoryCache(),
      link: authLink.concat(httpLink)
    })
  }

  if (!clientRef.current) {
    return <Loading />
  }

  return <ApolloProvider client={clientRef.current}><Outlet /></ApolloProvider>
}

export default withAuthenticationRequired(ApolloWrapper)
