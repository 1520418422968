import React, { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import ApolloErrorRenderer from '../../components/ApolloErrorRenderer'
import Loading from '../../components/Loading'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons'
import CSVDownload from '../../components/CSVDownload'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'

const CSV_HEADINGS = [
  'Company',
  'Username',
  'Root Folder',
  'Enabled'
]

function csvRow (user) {
  return [
    user.company_name,
    user.username,
    user.relative_root,
    !user.disabled
  ]
}

const GET_USERS = gql`query getSFTPUsers {
  sftpUsers {
    company_uuid
    relative_root
    username
    disabled
    company {
      company_name
    }
  }
}`

function SFTPUsersList ({ data }) {
  const [globalFilterValue, setGlobalFilterValue] = useState('')

  const users = data.sftpUsers
  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    setGlobalFilterValue(value)
  }
  return (
    <>
      <div className='flex justify-content-between flex-wrap mb-3'>
        <CSVDownload
          fileName='sftp_users.csv'
          headings={CSV_HEADINGS}
          records={users}
          recordToStringArrayFn={csvRow}
        />
        <div className='flex justify-content-end'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder='Keyword Search' />
          </span>
        </div>
      </div>
      <DataTable
        value={users}
        stripedRows
        rowHover
        resizableColumns
        size='small'
        paginator
        rows={50}
        sortField='company.company_name'
        sortOrder={1}
        globalFilter={globalFilterValue}
        globalFilterFields={['company.company_name', 'username', 'relative_root']}
      >
        <Column sortable filter header='Company' field='company.company_name' body={u => <Link to={`/workspaces/${u.company_uuid}`}>{u.company.company_name}</Link>} />
        <Column sortable filter header='Username' field='username' />
        <Column sortable filter header='Root Folder' field='relative_root' />
        <Column sortable header='Enabled' field='disabled' body={u => <FontAwesomeIcon icon={u.disabled ? faThumbsDown : faThumbsUp} />} />
      </DataTable>
    </>
  )
}

function List () {
  const { loading, error, data } = useQuery(GET_USERS)

  if (error) return <ApolloErrorRenderer error={error} />

  if (loading || !data) return <Loading fullPage />

  return (
    <>
      <h1>Users</h1>
      <SFTPUsersList data={data} />
    </>
  )
}

export default List
