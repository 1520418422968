import _ from 'lodash'
import DateValue from './DateValue'
import CopyButton from './CopyButton'
import { DataView } from 'primereact/dataview'
import './FieldListView.scss'

function ListDefItem ({ data, def }) {
  const { title, value, isDate, clipboard } = def

  const toPrint = _.isFunction(value) ? value(data) : _.get(data, value)

  return (
    <div className='col-12 p-2 field-list-view-item' key={def.title}>
      {title && <div className='text-sm font-light'>{title}</div>}
      {isDate ? <DateValue date={toPrint} /> : toPrint}
      {clipboard && <CopyButton text={toPrint} size='sm' className='ml-1' />}
    </div>
  )
}

function FieldListView ({ data, listDefinition, variant }) {
  const itemTemplate = (item) => {
    return (
      <ListDefItem data={data} def={item} />
    )
  }
  return (
    <DataView
      className='field-list-view'
      value={listDefinition}
      layout='list'
      itemTemplate={itemTemplate}
    />
  )
}

export default FieldListView
