import { gql, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import Loading from '../../components/Loading'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import _ from 'lodash'
import { Button } from 'primereact/button'
import { FilterMatchMode } from 'primereact/api'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import CopyableUUID from '../../components/CopyableUUID'
import { Link } from 'react-router-dom'

const GET_EXECUTIONS = gql`query embeddedExecutionSearch($pageSize: Int, $cursor: String,
  $client_id: ID, $status: String, $date_start: String, $date_end: String, $server_type: ID) {
  embeddedExecutionSearch(page_size: $pageSize, cursor: $cursor,
    client_id: $client_id, status: $status, date_start: $date_start, date_end: $date_end, server_type: $server_type) {
    recordsReturned
    totalRecords
    hasMoreRecords
    executions: data {
      server_type
      execution_id
      client_request_id
      client {
        client_id
        name
      }
      client_request {
        path
        ip
        method
      }
      client_response
      client_owner_id
      connection {
        server_type
        connection_id
      }
      start_time
      status
      status_code
      environment
      cursor
    }
  }
}`

function ClientFilter ({ value, onChange }) {
  const { data, loading, error } = useQuery(gql`query clientFilters {
    embeddedExecutionSearchFilters {
      client_id {
        id
        value
      }
    }
  }`)

  return (
    <Dropdown
      id='clientFilter'
      filter
      disabled={loading || error}
      placeholder='Client'
      showClear
      optionLabel='value'
      optionValue='id'
      options={data?.embeddedExecutionSearchFilters?.client_id || []}
      value={value}
      onChange={onChange}
    />
  )
}

function DateFilter ({ id, placeholderText, value, onChange }) {
  return (
    <Calendar
      id={id}
      placeholder={placeholderText}
      showTime
      hourFormat='24'
      readOnlyInput
      value={value}
      onChange={onChange}
      showIcon
      showButtonBar
    />
  )
}

function StatusFilter ({ value, onChange }) {
  const options = [
    { label: 'Success', value: 'success' },
    { label: 'Error', value: 'error' }
  ]

  return (
    <Dropdown
      id='statusFilter'
      filter
      placeholder='Status'
      showClear
      options={options}
      value={value}
      onChange={onChange}
    />
  )
}

function List () {
  const [variables, setVariables] = useState({
    pageSize: 100
  })
  const [clientFilter, setClientFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('')
  const [startFilter, setStartFilter] = useState(null)
  const [endFilter, setEndFilter] = useState(null)
  const [lazyLoading, setLazyLoading] = useState(false)

  const { data, loading, error, fetchMore, refetch } = useQuery(GET_EXECUTIONS, { variables })

  useEffect(() => {
    const _variables = {
      pageSize: 100
    }
    if (clientFilter?.length) {
      _variables.client_id = clientFilter
    }
    if (statusFilter?.length) {
      _variables.status = statusFilter
    }
    if (startFilter) {
      _variables.date_start = startFilter.toISOString()
    }
    if (endFilter) {
      _variables.date_end = endFilter.toISOString()
    }

    setVariables(_variables)
    refetch(_variables)
  }, [clientFilter, statusFilter, startFilter, endFilter])

  const handleFetchMore = (event) => {
    const cursor = _.last(data.embeddedExecutionSearch.executions)?.cursor
    if (!cursor || cursor === lazyLoading) return
    setLazyLoading(cursor)
    fetchMore({
      variables: {
        ...variables,
        cursor
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        console.log('updateQuery', cursor, _.last(fetchMoreResult.embeddedExecutionSearch.executions).cursor)
        if (!fetchMoreResult) return prev
        setLazyLoading(false)
        return {
          embeddedExecutionSearch: {
            ...fetchMoreResult.embeddedExecutionSearch,
            executions: [
              ...prev.embeddedExecutionSearch.executions,
              ...fetchMoreResult.embeddedExecutionSearch.executions
            ]
          }
        }
      }
    })
  }

  if (!data && !error) return <Loading fullPage />
  return (
    <div>
      <h1>Embedded Executions</h1>
      <div className='my-4'>
        <ClientFilter
          value={clientFilter}
          onChange={e => setClientFilter(e.value)}
        />
        <StatusFilter
          value={statusFilter}
          onChange={e => setStatusFilter(e.value)}
        />
        <DateFilter
          id='startFilter'
          placeholderText='Start Time'
          value={startFilter}
          onChange={e => setStartFilter(e.value)}
        />
        <DateFilter
          id='endFilter'
          placeholderText='End Time'
          value={endFilter}
          onChange={e => setEndFilter(e.value)}
        />
      </div>
      {error
        ? <p>Error loading embedded execution search data: {error.message}</p>
        : <>
          <DataTable
            value={data?.embeddedExecutionSearch?.executions || []}
            loading={loading || lazyLoading || error}
          >
            <Column field='client.name' header='Client' body={e => <Link to={`/embedded_executions/${e.execution_id}`}>{e.client.name}</Link>} />
            <Column field='status' header='Status' />
            <Column field='server_type' filterMatchMode='equals' filterMatchModeOptions={[{ label: 'Equals', value: FilterMatchMode.EQUALS }]} header='Server Type' />
            <Column field='execution_id' header='Execution Id' body={e => <CopyableUUID uuid={e.execution_id} />} />
            <Column field='connection.connection_id' header='Connection Id' body={e => <CopyableUUID uuid={e.connection?.connection_id} />} />
            <Column field='start_time' header='Start Time' />
            <Column field='client_request.path' header='Path' />
          </DataTable>
          <Button
            onClick={handleFetchMore}
            disabled={error || lazyLoading || !data.embeddedExecutionSearch.hasMoreRecords}
            label='Load More'
          />
          {
            // Something about the ternary above throws off the linting location, so disabling it for the next line
            // eslint-disable-next-line react/jsx-closing-tag-location
          }</>}
    </div>
  )
}

export default List
