import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import FallbackComponent from './FallbackComponent'

function ApolloErrorRenderer ({ error }) {
  useEffect(() => {
    console.error(error)
    Sentry.addBreadcrumb({
      category: 'graphql',
      data: { errors: JSON.stringify(error, null, 2) }
    })
    Sentry.captureException(new Error('Graphql Error'))
  }, [error])

  return <FallbackComponent />
}

export default ApolloErrorRenderer
