import { gql, useQuery } from '@apollo/client'
import ApolloErrorRenderer from '../../components/ApolloErrorRenderer'
import Loading from '../../components/Loading'
import SecretView from '../../components/SecretView'
import NewClientButton from './NewClientButton'
import CSVDownload from '../../components/CSVDownload'
import { DataTable } from 'primereact/datatable'
import CopyableUUID from '../../components/CopyableUUID'
import { Column } from 'primereact/column'

const CSV_HEADINGS = [
  'Name',
  'Client ID',
  'Environment',
  'Last Update'
]

function csvRow (client) {
  return [
    client.name,
    client.client_id,
    client.environment,
    client.updated
  ]
}

const GET_CLIENTS = gql`query getEmbeddedClients {
  embeddedClients {
    client_id
    client_secret
    name
    environment
    updated
  }
}`

function ClientsList ({ data }) {
  const clients = data.embeddedClients
  return (
    <>
      <CSVDownload
        fileName='embedded_clients.csv'
        records={clients}
        recordToStringArrayFn={csvRow}
        headings={CSV_HEADINGS}
      />
      <DataTable
        value={clients}
        stripedRows
        rowHover
        resizableColumns
        size='small'
        paginator
        rows={50}
        sortField='name'
        sortOrder={1}
        className='mt-3'
      >
        <Column sortable filter header='Name' field='name' />
        <Column sortable filter header='Client ID' field='client_id' body={client => <CopyableUUID uuid={client.client_id} />} />
        <Column sortable filter header='Environment' field='environment' />
        <Column header='Secret' field='client_secret' body={client => <SecretView text={client.client_secret} />} />
        <Column sortable filter header='Last Update' field='updated' />
      </DataTable>
    </>
  )
}

function List () {
  const { loading, error, data } = useQuery(GET_CLIENTS)

  if (error) return <ApolloErrorRenderer error={error} />

  if (loading || !data) return <Loading />

  return (
    <>
      <h1>Embedded Clients</h1>
      <NewClientButton />
      <ClientsList data={data} />
    </>
  )
}

export default List
