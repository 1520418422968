import { gql, useQuery, useMutation } from '@apollo/client'
import { useState, useEffect } from 'react'
import _ from 'lodash'
import ApolloErrorRenderer from '../../components/ApolloErrorRenderer'
import Loading from '../../components/Loading'
import { DateTime } from 'luxon'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { Message } from 'primereact/message'
import { Calendar } from 'primereact/calendar'

const GET_WORKSPACES = gql`query getWorkspaces {
  platformCompanies{
    company_uuid
    company_name
    created_date
  }
}`

const START_QUERY = gql`mutation startFlowUsageQuery($input: AddFlowUsageQueryInput) {
  addFlowUsageQuery(input: $input) {
    execution_id
    status
    file_name
    workspace {
      company_uuid
      company_name
    }
    start_date
    end_date
    created_at
    updated_at
  }}`

function GenerateReportButton ({ workspaceId, startDate, endDate, isValid, setShow, refetch }) {
  const [generateMutation, { called, loading, error }] = useMutation(START_QUERY)
  const prepDate = (date) => DateTime.fromJSDate(date).setZone('utc', { keepLocalTime: true }).toISO()
  const createFlowStatsQuery = async () => {
    await generateMutation({
      variables: {
        input: {
          workspace_id: workspaceId,
          start_date: prepDate(startDate),
          end_date: prepDate(endDate)
        }
      }
    })
    refetch()
    setShow(false)
  }

  useEffect(() => {
    if (!error) return
    window.alert(JSON.stringify(error))
    console.error(error)
  }, [error])

  return (
    <Button
      disabled={!isValid || called || loading || error}
      onClick={createFlowStatsQuery}
    >
      {called ? ('Running Report') : ('Generate Report')}
    </Button>
  )
}

function ModalFormBody ({ setShow, refetch }) {
  const { loading, error, data } = useQuery(GET_WORKSPACES)
  const [workspace, setWorkspace] = useState('')
  const [dateRange, setDateRange] = useState([new Date(DateTime.now().minus({ months: 1 }).startOf('month').toISO()), new Date(DateTime.now().startOf('month').toISO())])
  const [isValid, setIsValid] = useState(false)
  const [startError, setStartError] = useState('')
  const [endError, setEndError] = useState('')
  const [workspaceError, setWorkspaceError] = useState('')

  useEffect(() => {
    let valid = true

    if (_.isEmpty(workspace)) {
      valid = false
      setWorkspaceError('Select a workspace')
    } else {
      setWorkspaceError('')
    }

    if (!dateRange[0]) {
      valid = false
      setStartError('Start date must be selected')
    } else {
      setStartError('')
    }
    if (!dateRange[1]) {
      valid = false
      setEndError('End date must be selected')
    } else {
      setEndError('')
    }
    if (DateTime.fromISO(dateRange[1]) < DateTime.fromISO(dateRange[0])) {
      valid = false
      setEndError('End date must after start date')
    }

    if (valid) {
      setStartError('')
      setEndError('')
    }

    setIsValid(valid)
  }, [dateRange, workspace])

  if (error) return <ApolloErrorRenderer error={error} />
  if (loading || !data) return <ModalLoadingBody />

  const workspaceOptions = _.orderBy(data.platformCompanies, [r => _.get(r, 'company_name', '').toLowerCase()]).map((rec, idx) => {
    return { label: _.get(rec, 'company_name'), value: _.get(rec, 'company_uuid') }
  })

  return (
    <>
      <div>
        <label>Workspace</label>
        <Dropdown
          value={workspace}
          onChange={e => setWorkspace(e.value)}
          options={workspaceOptions}
          className='w-full'
        />
        {workspaceError && <Message severity='error' text={workspaceError} className='w-full' />}
      </div>
      <div className='mt-3'>
        <label>Start Date</label>
        <Calendar value={dateRange} onChange={(e) => setDateRange(e.value)} selectionMode='range' className='w-full' />
        {startError && <Message severity='error' text={startError} className='w-full' />}
        {endError && <Message severity='error' text={endError} className='w-full' />}
      </div>
      <div className='mt-3'>
        <GenerateReportButton isValid={isValid} workspaceId={workspace} startDate={dateRange[0]} endDate={dateRange[1]} setShow={setShow} refetch={refetch} />
      </div>
    </>
  )
}

function ModalLoadingBody () {
  return <Loading />
}

function ModalErrorResponse ({ error }) {
  return (
    <Message severity='error'>
      <pre>{JSON.stringify(error, null, 2)}</pre>
    </Message>
  )
}

function NewReportButton ({ refetch }) {
  const [show, setShow] = useState(false)

  const { loading, error, data } = useQuery(GET_WORKSPACES)

  if (error) return <ApolloErrorRenderer error={error} />

  return (
    <>
      <Button
        disabled={loading || !data}
        icon={`pi ${loading ? 'pi-spin pi-spinner' : 'pi-plus'}`}
        onClick={() => setShow(true)}
        label='New Usage Report'
      />
      <Dialog header='New Usage Report' visible={show} onHide={() => setShow(false)} dismissableMask>
        <ModalFormBody setShow={setShow} refetch={refetch} />
        {loading && <ModalLoadingBody />}
        {error && <ModalErrorResponse error={error} />}
      </Dialog>
    </>

  )
}

export default NewReportButton
