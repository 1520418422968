import isProduction from '../utils/isProduction'
import ExternalLink from './ExternalLink'

const MAXIO_URL = isProduction() ? 'https://j12.saasoptics.com/chainio/so/customers/' : 'https://app.saasoptics.com/sandbox_062821_chainio/so/customers/'

function MaxioLink ({ customerID }) {
  return (
    <ExternalLink href={`${MAXIO_URL}${customerID}/`} target='_blank' rel='noreferrer'>{customerID}</ExternalLink>
  )
}

export default MaxioLink
