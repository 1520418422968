import isProduction from './isProduction'

const BASE = isProduction() ? 'https://portal.chain.io' : 'https://red-portal.chain.io'

function portalUrl (href) {
  const urlStr = `${BASE}${href.match(/^\//) ? '' : '/'}`
  return `${urlStr}${href}`
}

export default portalUrl
