import { gql, useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Button } from 'primereact/button'
import FieldListView from '../../components/FieldListView'
import Loading from '../../components/Loading'
import SecretView from '../../components/SecretView'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Message } from 'primereact/message'
import { Dropdown } from 'primereact/dropdown'

const NEW_CLIENT = gql`mutation addClient($input: CreateEmbeddedClient!) {
  addEmbeddedClient(input: $input) {
    client_id
    client_secret
    name
    environment
  }
}`

function ModalFormBody ({ name, environment, nameError, setName, setEnvironment, isValid, makeNewClient }) {
  return (
    <>
      <div>
        <label htmlFor='newClientName'>Name</label>
        <InputText
          label='Name'
          value={name}
          onChange={evt => setName(evt.target.value)}
          id='newClientName'
          className='w-full'
        />
        {nameError && <Message severity='error' text={nameError} className='w-full' />}
      </div>
      <div>
        <label htmlFor='environment'>Environment</label>
        <Dropdown
          name='environment'
          value={environment}
          onChange={evt => setEnvironment(evt.target.value)}
          id='environment'
          className='w-full'
          options={[
            { label: 'Production', value: 'production' },
            { label: 'Sandbox', value: 'sandbox' }
          ]}
        />
      </div>
      <div className='mt-4'>
        <Button
          onClick={makeNewClient}
          disabled={!isValid}
          label='Create'
        />
      </div>
    </>
  )
}

function ModalLoadingBody () {
  return <Loading />
}

const COMPLETE_FIELDS = [
  {
    title: 'Client ID',
    value: 'client_id',
    clipboard: true
  },
  {
    title: 'Secret',
    value: (obj) => <SecretView text={obj.client_secret} />
  },
  {
    title: 'Client Name',
    value: 'name'
  },
  {
    title: 'Environment',
    value: 'environment'
  }
]
function ModalCompleteBody ({ newClientResponse }) {
  return (
    <>
      <h3>Client Created</h3>
      <FieldListView data={newClientResponse.addEmbeddedClient} listDefinition={COMPLETE_FIELDS} />
    </>
  )
}

function ModalErrorResponse ({ error }) {
  return (
    <Message severity='error'>
      <pre>{JSON.stringify(error, null, 2)}</pre>
    </Message>
  )
}

function NewClientButton ({ refetch = () => {} }) {
  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [environment, setEnvironment] = useState('production')
  const [nameError, setNameError] = useState(null)
  const [isValid, setIsValid] = useState(false)
  const [newClientResponse, setNewClientResponse] = useState(null)

  useEffect(() => {
    if (!name || !name.match(/\w{3}/)) {
      setNameError('Name must include at least 3 letters or numbers.')
      setIsValid(false)
    } else {
      setNameError(null)
      setIsValid(true)
    }
  }, [name])

  const [newClientMutation, { loading, error, data }] = useMutation(NEW_CLIENT)

  useEffect(() => {
    setNewClientResponse(data)
    refetch()
  }, [data, refetch])

  const makeNewClient = () => {
    newClientMutation({
      variables: {
        input: {
          name,
          environment
        }
      }
    })
  }

  return (
    <>
      <Button
        onClick={() => setShow(true)}
        label='New Client'
      />
      <Dialog
        visible={show}
        onHide={() => setShow(false)}
        dismissableMask
        header='Create New Client'
        style={{ width: '50vw' }}
      >
        {!data && !loading && (
          <ModalFormBody
            name={name}
            environment={environment}
            setName={setName}
            setEnvironment={setEnvironment}
            nameError={nameError}
            isValid={isValid}
            makeNewClient={makeNewClient}
          />
        )}
        {loading && <ModalLoadingBody />}
        {newClientResponse && <ModalCompleteBody
          newClientResponse={newClientResponse}
                              />}
        {error && <ModalErrorResponse error={error} />}
      </Dialog>
    </>
  )
}

export default NewClientButton
