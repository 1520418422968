import { gql, useMutation, useQuery } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'
import _ from 'lodash'
import ApolloErrorRenderer from '../../components/ApolloErrorRenderer'
import Loading from '../../components/Loading'
import DateValue from '../../components/DateValue'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import portalUrl from '../../utils/portalUrl'
import ExternalLink from '../../components/ExternalLink'
import { Card } from 'primereact/card'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import SimpleDashboardWidget from '../../components/SimpleDashboardWidget'
import { Message } from 'primereact/message'

const GET_USER = gql`query getFullUserData($email:String!) {
  platformUser(email:$email) {
    email
    first_name
    last_name
    companies {
      user_role
      company_uuid
      company_name
      last_access_date
      enabled
      is_last_owner
    }
    notifiers {
      flow_uuid
      flow_name
      company_uuid
      company_name
      partner_uuid
    }
  }
}`

const DISABLE_USER = gql`mutation bulkUpdateUserStatus($input: BulkUserStatus) {
  bulkUpdateUserStatuses(input: $input) {
    email
  }
}`

function DisableAllButton ({ user }) {
  const [disableMutation, { called, loading, error }] = useMutation(DISABLE_USER)
  const doDisable = async () => {
    if (!window.confirm('Are you sure you want to disable for all workspaces?')) {
      return
    }
    const companyUUIDs = user.companies.filter(c => !c.is_last_owner).map(c => c.company_uuid)
    disableMutation({
      variables: {
        input: {
          company_uuids: companyUUIDs,
          email: user.email,
          enabled: false
        }
      },
      refetchQueries: [
        {
          query: GET_USER
        },
        'getFullUserData'
      ]
    })
  }

  useEffect(() => {
    if (!error) return
    window.alert(JSON.stringify(error))
    console.error(error)
  }, [error])

  const hasEnabled = user.companies.find(c => c.enabled)

  return (
    <Button
      severity='danger'
      disabled={!hasEnabled || called || loading || error}
      onClick={doDisable}
      label='Disable All'
      icon={<FontAwesomeIcon icon={loading ? faSpinner : faThumbsDown} spin={loading} className='mr-2' />}
    />
  )
}

function DisableOneButton ({ user, company }) {
  const [disableMutation, { called, loading, error }] = useMutation(DISABLE_USER)
  const doDisable = async () => {
    if (!window.confirm(`Are you sure you want to disable for ${company.company_name}?`)) {
      return
    }
    disableMutation({
      variables: {
        input: {
          company_uuids: [company.company_uuid],
          email: user.email,
          enabled: false
        }
      },
      refetchQueries: [
        {
          query: GET_USER
        },
        'getFullUserData'
      ]
    })
  }

  useEffect(() => {
    if (!error) return
    window.alert(JSON.stringify(error))
    console.error(error)
  }, [error])

  return (
    <Button
      severity='danger'
      size='small'
      disabled={called || loading || error || !company.enabled}
      onClick={doDisable}
      label='Disable'
      icon={<FontAwesomeIcon icon={loading ? faSpinner : faThumbsDown} spin={loading} className='mr-2' />}
    />
  )
}

function View () {
  const params = useParams()
  const email = decodeURIComponent(params.email)

  const { loading, error, data, refetch } = useQuery(GET_USER, {
    variables: {
      email
    }
  })

  const user = data?.platformUser

  if (error) return <ApolloErrorRenderer error={error} />

  if (loading || !data) return <Loading />

  if (!user) {
    return (<Message severity='error' text={`Email ${email} not found.`} />)
  }

  const companies = _(user.companies)
    .map(c => ({ ...c, _lc: c.company_name.toLowerCase() }))
    .orderBy(['_lc', 'company_uuid'])
    .value()

  const groupedNotifiers = _.groupBy(user.notifiers, 'company_name')

  return (
    <div className='p2'>
      <h1>User: {user.email}</h1>
      <div className='grid'>
        <div className='col-12 md:col-8'>
          <Card title='Companies'>
            <DataTable
              stripedRows
              className='p-datatable-sm'
              value={companies}
              header={<DisableAllButton user={user} refetch={refetch} />}
            >
              <Column field='company_name' header='Workspace' body={company => <Link to={`/workspaces/${company.company_uuid}`}> {company.company_name}</Link>} />
              <Column field='user_role' header='Role' />
              <Column field='last_access_date' header='Last Accessed' body={company => <DateValue date={company.last_access_date} />} />
              <Column field='enabled' header='Enabled' body={company => <FontAwesomeIcon icon={company.enabled ? faThumbsUp : faThumbsDown} />} />
              <Column field='disable' header='' body={company => <DisableOneButton user={user} company={company} />} />
            </DataTable>
          </Card>
        </div>
        <div className='col-12 md:col-4'>
          <SimpleDashboardWidget title='Notifiers'>
            {Object.keys(groupedNotifiers).sort().map((c, cIdx) => (
              <div key={cIdx} className='py-2'>
                <div className='text-sm font-semibold'>{c}</div>
                {_.sortBy(groupedNotifiers[c], ['flow_name']).map((n, idx) => (
                  <div className='py-2' key={idx}>
                    <ExternalLink
                      target='_blank'
                      href={portalUrl(`/c/${n.company_uuid}/settings/tp/${n.partner_uuid}/fc/${n.flow_uuid}`)}
                    >{n.flow_name}
                    </ExternalLink>
                  </div>
                ))}
              </div>
            ))}
          </SimpleDashboardWidget>
        </div>
      </div>
    </div>
  )
}

export default View
