import _ from 'lodash'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useMemo, useState } from 'react'

import './IntegrationSetupChecklist.scss'

const CARGOWISE_REGEX = /(cargowise|cw_)/i

function makeBaseStep (integration, flow) {
  return {
    integration_name: integration?.selectedLicense?.name || 'unnamed integration',
    flow_name: flow.name || 'unnamed flow',
    flow_type: flow.flow_type || ''
  }
}

function hasCargowiseSourceFileType (flow) {
  return flow.source_file_type.match(CARGOWISE_REGEX)
}

function hasAS2 (flow) {
  return flow.source_protocol === 'AS2' || flow.destination_protocol === 'AS2'
}

function makeStep (integration, flow, description, durationDays, owner, problem) {
  return {
    ...makeBaseStep(integration, flow),
    description,
    durationDays,
    owner,
    problem
  }
}

function makeMissingFieldSteps (integration, flow) {
  const steps = []
  if (!flow.name || flow.name.length === 0) {
    steps.push(makeStep(integration, flow, 'Name the flow', 0, 'CSM', true))
  }
  if (!flow.source_protocol || flow.source_protocol.length === 0) {
    steps.push(makeStep(integration, flow, 'Select source protocol', 0, 'Customer', true))
  }
  if (!flow.source_file_type || flow.source_file_type.length === 0) {
    steps.push(makeStep(integration, flow, 'Select source file type', 0, 'Customer', true))
  }
  if (!flow.destination_file_type || flow.destination_file_type.length === 0) {
    steps.push(makeStep(integration, flow, 'Select destination file type', 0, 'Customer', true))
  }
  if (!flow.destination_protocol || flow.destination_protocol.length === 0) {
    steps.push(makeStep(integration, flow, 'Select destination protocol', 0, 'Customer', true))
  }
  return steps
}

function makeNewAdapterSteps (integration, flow) {
  const steps = []
  if (flow.source_file_type === 'new') {
    steps.push(makeStep(integration, flow, 'Analyze source file adapter', 3, 'BA'))
    steps.push(makeStep(integration, flow, 'Create source file adapter', 10, 'Development'))
    steps.push(makeStep(integration, flow, 'Test source file adapter', 5, 'QA'))
  }
  if (flow.destination_file_type === 'new') {
    steps.push(makeStep(integration, flow, 'Analyze destination file adapter', 3, 'BA'))
    steps.push(makeStep(integration, flow, 'Create destination file adapter', 10, 'Development'))
    steps.push(makeStep(integration, flow, 'Test destination file adapter', 5, 'QA'))
  }
  return steps
}

function makeEDIStepsInner (integration, flow, fileType) {
  const steps = []
  if (fileType.match(/stedi|chain\.io.*edi/i)) {
    const docNumber = fileType.match(/\d+/) ? fileType.match(/\d+/)[0] : ''
    let mapDuration = 3
    if (docNumber.match(/^(300|301|832|850|940)$/)) {
      mapDuration = 4
    }
    if (docNumber.match(/^(856)$/)) {
      mapDuration = 5
    }
    if (docNumber.match(/^(990)$/)) {
      mapDuration = 2
    }
    steps.push(makeStep(integration, flow, `Analyze ${fileType} requirements`, 2, 'BA'))
    steps.push(makeStep(integration, flow, `Map ${fileType}`, mapDuration, 'Development'))
    steps.push(makeStep(integration, flow, `Test ${fileType}`, 3, 'QA'))
  }
  return steps
}

function makeEDISteps (integration, flow) {
  const sourceFileType = flow.source_file_type || ''
  const destinationFileType = flow.destination_file_type || ''
  return [
    ...makeEDIStepsInner(integration, flow, sourceFileType),
    ...makeEDIStepsInner(integration, flow, destinationFileType)
  ]
}

function makeOverallSteps () {
  const steps = []
  steps.push(makeStep({ selectedLicense: { name: 'General Steps' } }, { name: 'Additional Steps' }, 'End To End Testing', 2, 'Customer'))
  steps.push(makeStep({ selectedLicense: { name: 'General Steps' } }, { name: 'Additional Steps' }, 'Go Live', 1, 'Customer'))
  steps.push(makeStep({ selectedLicense: { name: 'General Steps' } }, { name: 'Additional Steps' }, 'Hypercare', 5, 'Customer'))
  return steps
}

function makeSingleIntegrationSteps (integration) {
  const flows = integration.flows || []
  if (flows.length === 0) return []
  const steps = []
  if (integration.flowCountWarnings?.length > 0) {
    steps.push(makeStep(integration, { name: `Custom Solutioning ${integration.selectedLicense.name}` }, 'Prepare custom solution request', 0, 'CSM'))
    steps.push(makeStep(integration, { name: `Custom Solutioning ${integration.selectedLicense.name}` }, 'Approve custom solution request', 2, 'Executive Approval Team'))
  }
  flows.forEach((flow, idx) => {
    makeMissingFieldSteps(integration, flow).forEach(step => steps.push(step))
    makeNewAdapterSteps(integration, flow).forEach(step => steps.push(step))
    makeEDISteps(integration, flow).forEach(step => steps.push(step))
    if (hasCargowiseSourceFileType(flow)) {
      steps.push(makeStep(integration, flow, 'Configure Cargowise triggers', 2, 'Customer'))
    }
    if (hasAS2(flow)) {
      steps.push(makeStep(integration, flow, 'Configure AS2', 1, 'Customer'))
      steps.push(makeStep(integration, flow, 'Test AS2', 2, 'Customer'))
    }
    steps.push(makeStep(integration, flow, 'Configure flow in portal', 0.25, 'Customer'))
    steps.push(makeStep(integration, flow, 'Acceptance test flow', 2, 'Customer'))
    return steps.map((step, sIdx) => ({ ...step, id: `step-${idx}-${sIdx}` }))
  })
  if (integration.flowCountWarnings?.length > 0) {
    steps.push(makeStep(integration, { name: 'Additional Steps' }, `Deploy custom SKU ${integration.selectedLicense.name}`, 0.5, 'Product'))
  }
  return steps
}

function makeIntegrationSetupChecklist (integrations) {
  if (!integrations) return []
  const steps = []
  integrations.forEach(integration => {
    makeSingleIntegrationSteps(integration).forEach(step => steps.push(step))
  })
  makeOverallSteps().forEach(step => steps.push(step))
  return steps
}

function IntegrationSetupChecklist ({ integrations }) {
  const [data, setData] = useState([])

  useMemo(() => {
    const data = makeIntegrationSetupChecklist(integrations)
    setData(data)
  }, [integrations])

  if (data.length === 0) return null

  const durationByFlowName = _(data).groupBy('flow_name').mapValues(steps => _.sumBy(steps, 'durationDays')).value()
  const durationByIntegration = _(data).groupBy('integration_name').mapValues(steps => _.sumBy(steps, 'durationDays')).value()

  return (
    <DataTable
      value={data}
      rowHover
      groupRowsBy='flow_name'
      rowGroupMode='rowspan'
    >
      <Column
        rowGroup
        field='integration_name'
        header='Integration Name'
        body={(rowData) => {
          const firstRow = data.find(r => r.integration_name === rowData.integration_name)
          if (firstRow === rowData) {
            return (
              <>
                <strong>{rowData.integration_name}</strong><br />
                <small>({durationByIntegration[rowData.integration_name]} days)</small>
              </>
            )
          }
          return <>&nbsp;</>
        }}
        bodyClassName={(rowData) => {
          const firstRow = data.find(r => r.integration_name === rowData.integration_name)
          if (firstRow !== rowData) {
            return 'integration-checklist-no-top-border'
          }
          return null
        }}
      />
      <Column
        rowGroup
        field='flow_name'
        header='Flow Name'
        body={(rowData) => (
          <div>
            <strong>{rowData.flow_name}</strong><br />
            <small>{rowData.flow_type} ({durationByFlowName[rowData.flow_name]} days)</small>
          </div>
        )}
        bodyClassName='integration-checklist-flow-group'
      />
      <Column
        field='description'
        header='Description'
        body={(rowData) => <span>{rowData.problem ? '🚨' : <>&nbsp;</>} {rowData.description}</span>}
      />
      <Column field='durationDays' header={`Duration (${_.sumBy(data, 'durationDays')} days)`} />
      <Column field='owner' header='Owner' />
    </DataTable>
  )
}

export default IntegrationSetupChecklist
