import { Message } from 'primereact/message'
import React from 'react'

function FallbackComponent () {
  return (
    <Message severity='error' text="We're sorry, this component failed to render.  Please try again." />
  )
}

export default FallbackComponent
