import AppLinks from '../components/AppLinks'

function Links () {
  return (
    <div className='p-4'>
      <AppLinks />
    </div>
  )
}

export default Links
