import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, useState } from 'react'
import Loading from '../components/Loading'
import CopyButton from '../components/CopyButton'

function GraphQLAccess () {
  const { getAccessTokenSilently } = useAuth0()
  const [token, setToken] = useState(null)

  useEffect(() => { getAccessTokenSilently().then(setToken) }, [getAccessTokenSilently])

  if (!token) return <Loading fullPage />

  return (
    <div className='p-2'>
      <h1>GraphQL Access</h1>

      <p>You can use Postman or another tool to access the GraphQL API that supports this site.</p>
      <ol>
        <li>Setup a Postman Collection with Bearer Token Authorization</li>
        <li>Set the token value to the value below.</li>
        <li>Set each query you create to inherit authorization from the collection</li>
        <li>Each time you re-authenticate here, you'll need to replace the token in the collection</li>
        <li>The URL you access will be either https://red-portal-api.chain.io or https://portal-api.chain.io depending on environment.</li>
      </ol>
      <p>Here's your token: <CopyButton text={token} /></p>
      <pre>{token}</pre>
    </div>
  )
}

export default GraphQLAccess
