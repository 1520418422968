import LayoutPane from './LayoutPane'
import { Fieldset } from 'primereact/fieldset'
import { useEffect, useState } from 'react'

function MaxFlowsWarning ({ flowCountWarnings }) {
  if (flowCountWarnings.length === 0) return null
  return (
    <Fieldset legend='⚠️ Flow Counts Exceeded' className='my-2'>
      <p><strong>Flow Counts Exceeded. You will need a custom SKU to implement this plan.</strong></p>
      <ul>
        {flowCountWarnings.map(fw => (
          <li key={fw.name}> {fw.count} flows of type {fw.name}. Only {fw.max} allowed</li>
        ))}
      </ul>
    </Fieldset>
  )
}

function IntegrationPane ({ flowGroup, setFlows, setIntegrationName }) {
  const { id, flowCountWarnings, selectedLicense } = flowGroup
  const [maxFlows, setMaxFlows] = useState({})

  useEffect(() => {
    const newMaxFlows = {}
    if (selectedLicense) {
      selectedLicense.flows_allowed.forEach(fa => {
        newMaxFlows[fa.name] = fa.max_flows
      })
    }
    setMaxFlows(newMaxFlows)
  }, [selectedLicense])

  const innerSetFlows = (flows) => {
    setFlows(id, flows)
  }

  const innerSetIntegrationName = (name) => {
    setIntegrationName(id, name)
  }

  return (
    <div>
      <LayoutPane
        integration={flowGroup}
        setFlows={innerSetFlows}
        maxFlows={maxFlows}
        setIntegrationName={innerSetIntegrationName}
      />
      <MaxFlowsWarning
        flowCountWarnings={flowCountWarnings}
      />
    </div>
  )
}

export default IntegrationPane
