import { Card } from 'primereact/card'
import { Button } from 'primereact/button'
import _ from 'lodash'

function LicenseSelector ({ licenses, selectLicense }) {
  return (
    <div>
      <h2>Select a license</h2>
      <div className='grid'>
        {_.sortBy(licenses, 'name').map(license => (
          <div
            key={license.sku}
            className='col-12 md:col-4'
          >
            <Card
              title={license.name}
            >
              <p>{license.description}</p>
              <ul>
                {_.sortBy(license.flows_allowed, 'name').map(flow => (
                  <li key={flow.name}>{flow.name} <small>({flow.max_flows} max)</small></li>
                ))}
              </ul>
              <Button onClick={() => selectLicense(license)}>Select</Button>
            </Card>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LicenseSelector
