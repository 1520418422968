import { gql, useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import ApolloErrorRenderer from '../../../components/ApolloErrorRenderer'
import Loading from '../../../components/Loading'
import SimpleDashboardWidget from '../../../components/SimpleDashboardWidget'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons'
import customSKUValidator from '../../../utils/customSKUValidator'
import CopyButton from '../../../components/CopyButton'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Message } from 'primereact/message'
import { InputTextarea } from 'primereact/inputtextarea'
import { InputText } from 'primereact/inputtext'

const INTEGRATION_IDENTIFIER_REGEX = /^[a-z0-9]+\.(?:[a-z0-9]+_)+(?:[a-z0-9]+)$/i

function moveUp (array, startPosition) {
  if (startPosition >= array.length) return [...array]
  if (startPosition === 0) return [...array]
  const r = []
  array.forEach((obj, idx) => {
    if (idx < startPosition - 1 || idx > startPosition) {
      r[idx] = obj
    } else if (idx === startPosition - 1) {
      r[idx + 1] = obj
    } else if (idx === startPosition) {
      r[idx - 1] = obj
    }
  })
  return r
}

function moveDown (array, startPosition) {
  if (startPosition < 0) return [...array]
  if (startPosition >= array.length - 1) return [...array]
  const r = []
  array.forEach((obj, idx) => {
    if (idx < startPosition || idx > startPosition + 1) {
      r[idx] = obj
    } else if (idx === startPosition + 1) {
      r[idx - 1] = obj
    } else if (idx === startPosition) {
      r[idx + 1] = obj
    }
  })
  return r
}

const GET_SKUS = gql`query getSKUs($company_uuid: ID!) {
  platformCompany(company_uuid: $company_uuid) {
    custom_skus {
      type
      set_value {
        sku
        name
        description
        detailed_description
        price
        allowed_licenses
        transaction_count
        info_url
        hidden
        line_icon
        flows_allowed {
          flow_type
          integration_identifier
          name
          description
          source_file_types {
            code
          }
          destination_file_types {
            code
          }
          max_flows
        }
      }
      remove_value {
        sku
      }
      flow_count_value {
        integration_identifier
        max_flows
      }
      add_adapters_value {
        integration_identifier
        source_file_types
        destination_file_types
      }
      import_integration_value {
        sku
        integration_identifier
      }
      order
    }
  }
}`

const UPDATE_SKUS = gql`mutation($input: CustomSkuInput) {
  updateCustomSKU(input: $input) {
    type
    set_value {
      sku
      name
      description
      detailed_description
      price
      allowed_licenses
      transaction_count
      info_url
      hidden
      line_icon
      flows_allowed {
        flow_type
        integration_identifier
        name
        description
        source_file_types {
          code
        }
        destination_file_types {
          code
        }
        max_flows
      }
    }
    remove_value {
      sku
    }
    flow_count_value {
      integration_identifier
      max_flows
    }
    add_adapters_value {
      integration_identifier
      source_file_types
      destination_file_types
    }
    import_integration_value {
      sku
      integration_identifier
    }
    order
  }
}`

// Graphql enums have a weird quirk where periods are not allowed in them.
// Our integration identifiers use periods, so the following 2 functions
// translate the identifiers from the backend representation (sku_integration_id)
// to the standard one (sku.integration_id) and vice versa.
// This allows the user to write integration ids as they'd expect to and not
// handle the translation themselves.
function translateIntegrationIdentifierToBackend (identifier) {
  return `${identifier}`.replace('.', '_')
}

function translateIntegrationIdentifierToFrontend (identifier) {
  const [sku, ...integration] = `${identifier}`.split('_')
  if (sku) {
    return `${sku}.${integration.join('_')}`
  }
  return ''
}

function translateObject (obj, mutationCallbacks) {
  if (_.isNil(obj)) {
    return obj
  }

  if (_.isArray(obj)) {
    return obj.map((item) => translateObject(item, mutationCallbacks))
  } else if (_.isPlainObject(obj)) {
    const newObj = {}
    _.toPairs(obj).forEach(([key, value]) => {
      if (_.isFunction(mutationCallbacks[key])) {
        const callbackValue = mutationCallbacks[key](value)
        if (!_.isUndefined(callbackValue)) {
          newObj[key] = callbackValue
        }
      } else if (_.isPlainObject(value) || _.isArray(value)) {
        newObj[key] = translateObject(value, mutationCallbacks)
      } else {
        newObj[key] = value
      }
    })
    return newObj
  }

  return obj
}

function SKUActionBar ({ sku, index, actions, deleteSKU }) {
  return (
    <div style={{ marginTop: '1em' }}>
      <Button
        className='mr-1'
        onClick={() => actions.up(index)} title='Move Up' icon={
          <FontAwesomeIcon icon={faArrowUp} />
      }
      />
      <Button
        className='mr-1'
        onClick={() => actions.down(index)} title='Move Down' icon={
          <FontAwesomeIcon icon={faArrowDown} />
      }
      />
      <Button
        className='mr-1'
        onClick={() => deleteSKU(index)} title='Delete' icon={
          <FontAwesomeIcon icon={faTrash} />
      }
      />
    </div>
  )
}

function ViewRemove ({ sku, index, actions, deleteSKU }) {
  return (
    <div className='p-2'>
      <h2>Remove A SKU</h2>
      <strong>SKU:</strong> {sku.remove_value.sku}
      <SKUActionBar
        sku={sku}
        index={index}
        actions={actions}
        deleteSKU={deleteSKU}
      />
    </div>
  )
}

function ViewSetDetail ({ sku }) {
  const [show, setShow] = useState(false)

  return (
    <>
      <Button
        onClick={() => setShow(!show)}
        link
        text
        label={show ? 'Hide Detail' : 'Show Detail'}
      />
      {
        show &&
        (
          <>
            <pre>
              {JSON.stringify(sku.set_value, null, 2)}
            </pre>
            <CopyButton text={JSON.stringify(sku.set_value, null, 2)} />
          </>
        )
      }
    </>
  )
}

function ViewSet ({ sku, index, actions, deleteSKU }) {
  const sv = sku.set_value
  return (
    <div className='p-2'>
      <h2>Replace SKU Configuration For Workplace</h2>
      <strong>Set SKU:</strong> {sv.sku}
      <ViewSetDetail sku={sku} />
      <SKUActionBar
        sku={sku}
        index={index}
        actions={actions}
        deleteSKU={deleteSKU}
      />
    </div>
  )
}

function ViewFlowCount ({ sku, index, actions, deleteSKU }) {
  const fc = sku.flow_count_value
  return (
    <div className='p-2'>
      <h2>Change Flow Count</h2>
      <div><strong>Integration:</strong> {fc.integration_identifier}</div>
      <div><strong>Max Flow Count:</strong> {fc.max_flows}</div>
      <SKUActionBar
        sku={sku}
        index={index}
        actions={actions}
        deleteSKU={deleteSKU}
      />
    </div>
  )
}

function AdapterList ({ adapters, adapterType }) {
  if (adapters?.length) {
    return (
      <div><strong>{adapterType}</strong>
        <ul>
          {adapters.map((a, idx) => (<li key={idx}>{a}</li>))}
        </ul>
      </div>
    )
  }

  return <strong>No {adapterType} adapters configured.</strong>
}

function ViewAddAdapters ({ sku, index, actions, deleteSKU }) {
  const integrationIdentifier = sku.add_adapters_value.integration_identifier
  const sourceFileTypes = sku.add_adapters_value?.source_file_types || []
  const destinationFileTypes = sku.add_adapters_value?.destination_file_types || []
  return (
    <div className='p-2'>
      <h2>Add Adapters To A Flow</h2>
      <strong>Integration:</strong> {integrationIdentifier}
      <AdapterList adapters={sourceFileTypes} adapterType='Source File Type' />
      <AdapterList adapters={destinationFileTypes} adapterType='Destination File Type' />
      <SKUActionBar
        sku={sku}
        index={index}
        actions={actions}
        deleteSKU={deleteSKU}
      />
    </div>
  )
}

function ViewImportIntegration ({ sku, index, actions, deleteSKU }) {
  return (
    <div className='p-2'>
      <h2>Import Flow From Another SKU</h2>
      <div><strong>Target SKU:</strong> {sku.import_integration_value.sku}</div>
      <div><strong>Imported Integration:</strong> {sku.import_integration_value.integration_identifier}</div>
      <SKUActionBar
        sku={sku}
        index={index}
        actions={actions}
        deleteSKU={deleteSKU}
      />
    </div>
  )
}

const WIDGET_MAP = {
  remove: ViewRemove,
  set: ViewSet,
  flow_count: ViewFlowCount,
  add_adapters: ViewAddAdapters,
  import_integration: ViewImportIntegration
}

function CustomSKU ({ sku, actions, index, deleteSKU }) {
  const toRender = WIDGET_MAP[sku.type]
  if (!toRender) {
    return (
      <div className='p-2'>
        <Message severity='error' text={`Unknown Type: ${sku.type}`} />
      </div>
    )
  }
  return toRender({ sku, actions, index, deleteSKU })
}

function cleanSourceFileTypes (fa) {
  return {
    ...fa,
    source_file_types: !fa.source_file_types ? [] : fa.source_file_types.map(s => s.code),
    destination_file_types: !fa.destination_file_types ? [] : fa.destination_file_types.map(s => s.code)
  }
}

function cleanFlowsAllowed (val) {
  if (!val.set_value) return val
  const sv = val.set_value
  return {
    ...val,
    set_value: {
      ...sv,
      flows_allowed: !sv.flows_allowed ? [] : sv.flows_allowed.map(cleanSourceFileTypes)
    }
  }
}

function cleanInputValues (list) {
  const toReturn = _.cloneDeep(list)
  // remove __typename keys and translate integration_identifier keys
  const translated = translateObject(toReturn, { __typename: () => undefined, integration_identifier: translateIntegrationIdentifierToFrontend })
  return _.map(translated, cleanFlowsAllowed)
}

function SaveButton ({ customSKUs, loadedData, refetch }) {
  const params = useParams()
  const translateSKUS = (skus) => {
    return (skus || []).map(sku => translateObject(sku, { integration_identifier: translateIntegrationIdentifierToBackend }))
  }
  const canSave = customSKUs && !_.isEqual(translateSKUS(customSKUs), translateSKUS(loadedData))
  const [updateSKUMutation, { loading, error }] = useMutation(UPDATE_SKUS, {
    refetchQueries: [
      { query: GET_SKUS, variables: { company_uuid: params.company_uuid } }
    ]
  })

  const save = () => {
    updateSKUMutation({
      variables: {
        input: {
          company_uuid: params.company_uuid,
          values: translateSKUS(customSKUs)
        }
      }
    })
  }

  if (error) return <ApolloErrorRenderer error={error} />

  return (
    <Button
      onClick={save}
      disabled={!canSave || loading}
      label='Save'
      icon={loading ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
    />
  )
}

function AddRemoveInstructionButton ({ addSKU }) {
  const [show, setShow] = useState(false)
  const [sku, setSKU] = useState('')
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    setIsValid(!!sku.match(/\w/))
  }, [sku])

  const doAdd = () => {
    addSKU({
      type: 'remove',
      remove_value: {
        sku
      }
    })
    setSKU('')
    setShow(false)
  }

  return (
    <>
      <Button
        text
        onClick={() => setShow(true)}
        label='Remove A SKU'
        className='mr-1'
      />
      <Dialog
        header='Remove A SKU'
        visible={show}
        onHide={() => setShow(false)}
        dismissableMask
        style={{ width: '50vw' }}
        footer={(
          <Button onClick={doAdd} disabled={!isValid} label='Add' />
        )}
      >
        <div>
          <label htmlFor='sku'>SKU</label>
          <InputText
            name='sku'
            value={sku}
            onChange={evt => setSKU(evt.target.value)}
            className='w-full'
          />
        </div>
      </Dialog>
    </>
  )
}

function AddSetInstructionButton ({ addSKU }) {
  const [show, setShow] = useState(false)
  const [jsonObj, setJSONObj] = useState('')
  const [obj, setObj] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    try {
      const parsed = JSON.parse(jsonObj)
      const validationError = customSKUValidator('set', parsed)
      if (validationError) {
        setError(validationError)
        setObj(null)
      } else {
        setError(null)
        setObj({
          type: 'set',
          set_value: parsed
        })
      }
    } catch (error) {
      setObj(null)
      setError('Content must be a valid JSON object.')
    }
  }, [jsonObj])

  const doAdd = () => {
    addSKU(obj)
    setShow(false)
    setJSONObj('')
    setObj(null)
    setError(null)
  }

  return (
    <>
      <Button
        onClick={() => setShow(true)}
        text
        label='Add A Set Instruction'
        className='mr-1'
      />
      <Dialog
        header='Add A Set Instruction'
        visible={show}
        onHide={() => setShow(false)}
        dismissableMask
        style={{ width: '50vw' }}
        footer={(
          <Button onClick={doAdd} disabled={error} label='Add' />
        )}
      >
        <div>
          <Message severity='warn' text='Set Instructions are considered deprecated. Virtually all use cases for this custom sku type can be accomplished with one of the other of the sku types. Only use this instruction if you know what you are doing.' />
          <InputTextarea
            rows={10}
            value={jsonObj}
            onChange={evt => setJSONObj(evt.target.value)}
            className='w-full'
          />
          {error &&
            <Message severity='error' text={_.isString(error) ? error : JSON.stringify(error, null, 2)} />}
        </div>
      </Dialog>
    </>
  )
}

function AddFlowCountButton ({ addSKU }) {
  const [show, setShow] = useState(false)
  const [integration, setIntegration] = useState('')
  const [maxFlows, setMaxFlows] = useState('')
  const [obj, setObj] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    const errors = []
    try {
      if (!integration) {
        errors.push('Integration Identifier must have a value.')
      } else {
        if (!integration.match(INTEGRATION_IDENTIFIER_REGEX)) {
          errors.push(`Invalid Integration Identifier.  Must match pattern ${INTEGRATION_IDENTIFIER_REGEX}.`)
        }
      }

      const max = parseInt(maxFlows, 10)
      // the following handles NaN
      if (!(max >= 0)) {
        errors.push('Maximum Number Of Flows must be zero or a positive integer.')
        setMaxFlows('')
      }

      if (errors.length) {
        setError(errors.join(' '))
        setObj(null)
      } else {
        setError(null)
        setObj({
          type: 'flow_count',
          flow_count_value: {
            integration_identifier: integration,
            max_flows: parseInt(maxFlows, 10)
          }
        })
      }
    } catch (error) {
      setObj(null)
      setError(error.message)
    }
  }, [integration, maxFlows])

  const doAdd = () => {
    addSKU(obj)
    setShow(false)
    setIntegration('')
    setMaxFlows('')
    setObj(null)
    setError(null)
  }

  return (
    <>
      <Button
        onClick={() => setShow(true)}
        text
        label='Change Flow Count'
        className='mr-1'
      />
      <Dialog
        header='Change Flow Count'
        visible={show}
        onHide={() => setShow(false)}
        dismissableMask
        style={{ width: '50vw' }}
        footer={(
          <Button onClick={doAdd} disabled={error} label='Add' />
        )}
      >
        <div>
          <label htmlFor='integrationIdentifier'>Integration Identifier</label>
          <InputText
            name='integrationIdentifier'
            value={integration}
            onChange={evt => setIntegration(evt.target.value)}
            className='w-full'
          />
          <label htmlFor='maxFlows'>Maximum Number Of Flows</label>
          <InputText
            keyfilter='int'
            name='maxFlows'
            value={maxFlows}
            onChange={evt => setMaxFlows(evt.target.value)}
            className='w-full'
          />
          {error &&
            <Message severity='error' text={_.isString(error) ? error : JSON.stringify(error, null, 2)} />}
        </div>
      </Dialog>
    </>
  )
}

function AddImportIntegrationButton ({ addSKU }) {
  const [show, setShow] = useState(false)
  const [sku, setSku] = useState('')
  const [integration, setIntegration] = useState('')
  const [obj, setObj] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    const errors = []
    try {
      if (!sku) {
        errors.push('Sku must have a value')
      }

      if (!integration) {
        errors.push('Integration Identifier must have a value.')
      } else {
        if (!integration.match(INTEGRATION_IDENTIFIER_REGEX)) {
          errors.push(`Invalid Integration Identifier.  Must match pattern ${INTEGRATION_IDENTIFIER_REGEX}.`)
        }
      }

      if (errors.length) {
        setError(errors.join(' '))
        setObj(null)
      } else {
        setError(null)
        setObj({
          type: 'import_integration',
          import_integration_value: {
            sku,
            integration_identifier: integration
          }
        })
      }
    } catch (error) {
      setObj(null)
      setError(error.message)
    }
  }, [integration, sku])

  const doAdd = () => {
    addSKU(obj)
    setShow(false)
    setIntegration('')
    setSku('')
    setObj(null)
    setError(null)
  }

  return (
    <>
      <Button
        onClick={() => setShow(true)}
        text
        label='Import Flow From Another SKU'
        className='mr-1'
      />
      <Dialog
        header='Import Flow From Another SKU'
        visible={show}
        onHide={() => setShow(false)}
        dismissableMask
        style={{ width: '50vw' }}
        footer={(
          <Button onClick={doAdd} disabled={error} label='Add' />
        )}
      >
        <div>
          <p>Adds an integration from another SKU to the Target SKU.<br />For example, you can use this to add a data_send_passthrough integration to any sku.</p>
          <label htmlFor='sku'>Target SKU</label>
          <InputText
            name='sku'
            value={sku}
            onChange={evt => setSku(evt.target.value)}
            className='w-full'
          />
          <label htmlFor='integrationIdentifier'>Integration Identifier To Add To Target SKU</label>
          <InputText
            name='integrationIdentifier'
            value={integration}
            onChange={evt => setIntegration(evt.target.value)}
            className='w-full'
          />
          {error &&
            <Message severity='error' text={_.isString(error) ? error : JSON.stringify(error, null, 2)} />}
        </div>
      </Dialog>
    </>
  )
}

function AddAddAdaptersButton ({ addSKU }) {
  const [show, setShow] = useState(false)
  const [jsonObj, setJSONObj] = useState('')
  const [obj, setObj] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    try {
      const parsed = JSON.parse(jsonObj)
      const validationError = customSKUValidator('add_adapters', parsed)
      if (validationError) {
        setError(validationError)
        setObj(null)
      } else {
        setError(null)
        setObj({
          type: 'add_adapters',
          add_adapters_value: parsed
        })
      }
    } catch (error) {
      setObj(null)
      setError('Content must be a valid JSON object.')
    }
  }, [jsonObj])

  const doAdd = () => {
    addSKU(obj)
    setShow(false)
    setJSONObj('')
    setObj(null)
    setError(null)
  }

  return (
    <>
      <Button
        onClick={() => setShow(true)}
        text
        label='Add Adapters To A Flow'
        className='mr-1'
      />
      <Dialog
        header='Add Adapters To A Flow'
        visible={show}
        onHide={() => setShow(false)}
        dismissableMask
        style={{ width: '50vw' }}
        footer={(
          <Button onClick={doAdd} disabled={error} label='Add' />
        )}
      >
        <div>
          <p>Add additional adapter file types to a specific integration. The adapters added will be appended to the existing integration's adapter list. They will not replace the existing list.</p>
          <p>Example JSON:</p>
          <pre>&#123;<br />  "integration_identifier":"freightOps.receive_booking",<br />  "source_file_types":["someCustomInputAdapter"],<br />  "destination_file_types":["someCustomOutputAdapter","anotherOutputAdapter"]<br />&#125;</pre>
          <InputTextarea
            rows={10}
            value={jsonObj}
            onChange={evt => setJSONObj(evt.target.value)}
            className='w-full'
          />
          {error &&
            <Message severity='error' text={_.isString(error) ? error : JSON.stringify(error, null, 2)} />}
        </div>
      </Dialog>
    </>
  )
}

function CustomSKUs () {
  const [customSKUs, setCustomSKUs] = useState(null)
  const [loadedData, setLoadedData] = useState(null)
  const params = useParams()

  const { loading, error, data, refetch } = useQuery(GET_SKUS, { variables: { company_uuid: params.company_uuid } })

  useEffect(() => {
    if (!data?.platformCompany) return
    const cs = _.orderBy(data.platformCompany.custom_skus, ['order'])
    const toUse = cleanInputValues(cs)
    setLoadedData(toUse)
    setCustomSKUs(toUse)
  }, [data])

  if (error) return <ApolloErrorRenderer error={error} />

  if (loading || !customSKUs) return <Loading fullPage />

  const updateSKUs = (newList) => {
    const ordered = newList.map((obj, idx) => ({
      ...obj,
      order: idx
    }))
    setCustomSKUs(ordered)
  }

  const moveSKUUp = (index) => {
    updateSKUs(moveUp(customSKUs, index))
  }

  const moveSKUDown = (index) => {
    updateSKUs(moveDown(customSKUs, index))
  }

  const addSKU = (sku) => {
    updateSKUs([
      ...customSKUs,
      sku
    ])
  }

  const deleteSKU = (index) => {
    const base = [
      ...customSKUs
    ]
    base[index] = undefined
    updateSKUs(_.compact(base))
  }

  const actions = {
    up: moveSKUUp,
    down: moveSKUDown
  }

  return (
    <>
      <div className='p-2'>
        <h1>Custom SKUs</h1>
        <small>Use this screen to customize integrations for this workspace.</small>
        <SimpleDashboardWidget>
          {customSKUs.map((cs, idx) =>
            <CustomSKU
              sku={cs}
              key={idx}
              actions={actions}
              index={idx}
              deleteSKU={deleteSKU}
            />)}
        </SimpleDashboardWidget>
      </div>
      <div>
        <AddRemoveInstructionButton customSKUs={customSKUs} addSKU={addSKU} />
        <AddSetInstructionButton customSKUs={customSKUs} addSKU={addSKU} />
        <AddFlowCountButton customSKUs={customSKUs} addSKU={addSKU} />
        <AddImportIntegrationButton customSKUs={customSKUs} addSKU={addSKU} />
        <AddAddAdaptersButton customSKUs={customSKUs} addSKU={addSKU} />
        <SaveButton
          customSKUs={customSKUs}
          loadedData={loadedData}
          refetch={refetch}
        />
      </div>
    </>
  )
}

export default CustomSKUs
