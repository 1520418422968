import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Dialog } from 'primereact/dialog'
import { Tag } from 'primereact/tag'
import { useState } from 'react'
import _ from 'lodash'
import DateValue from './DateValue'

function Header ({ title, icon, owner }) {
  return (
    <div className='flex flex-row'>
      <div className='flex-grow-1'>
        {icon && <span className='mr-2'>{icon}</span>}
        {title}
      </div>
      {owner && <div className='pl-2'><Tag value={owner} /></div>}
    </div>
  )
}

function LongDescription ({ longDescription }) {
  if (!longDescription) return null
  return (
    <div>
      {longDescription.split('\n').map((paragraph, idx) => (
        paragraph?.trim().length ? <p key={idx}>{paragraph}</p> : null
      ))}
    </div>
  )
}

function GoalPanel ({ title, description, longDescription, owner, due, pathToComplete, icon, tags = [], themeColor = '#21211D', color = 'white', fullColor = true, className = '' }) {
  const [dialogVisible, setDialogVisible] = useState(false)

  let cardStyle = {
    backgroundColor: themeColor,
    color
  }

  const dialogHeaderStyle = {
    backgroundColor: themeColor,
    color
  }

  if (!fullColor) {
    cardStyle = {
      backgroundColor: 'white',
      borderLeft: `4px solid ${themeColor}`
    }
  }

  return (
    <>
      <Card
        title={<Header title={title} icon={icon} owner={owner} />}
        className={`${className} h-full`}
        style={cardStyle}
        pt={{
          body: {
            className: 'h-full flex flex-column'
          },
          content: {
            className: 'flex flex-column flex-grow-1'
          }
        }}
      >
        <div className='flex flex-grow-1'>
          <p>{description}</p>
        </div>
        {tags?.length > 0 &&
          <div className='py-2'>
            {_.compact(tags).map((tag) => (
              <Tag
                value={tag}
                className='mr-2'
                key={tag}
                style={{
                  backgroundColor: fullColor ? 'white' : themeColor,
                  color: fullColor ? themeColor : 'white'
                }}
              />
            ))}
          </div>}
        <div className='text-right'>
          <Button label='More' text severity='secondary' onClick={() => setDialogVisible(true)} />
        </div>
      </Card>
      <Dialog
        header={<><span className='mr-2'>{icon}</span>{title}</>}
        headerStyle={dialogHeaderStyle}
        visible={dialogVisible}
        style={{ width: '50vw' }}
        onHide={() => setDialogVisible(false)}
        dismissableMask
      >
        <div className='mb-4'>
          <h3>Description</h3>
          <LongDescription longDescription={longDescription} />
          {pathToComplete && (
            <>
              <hr />
              <h3>Path to Complete</h3>
              {pathToComplete.split('\n').map((paragraph, idx) => (
                paragraph?.trim().length ? <p key={idx}>{paragraph}</p> : null
              ))}
            </>
          )}
          <hr />
          <div className='flex flex-row justify-between'>
            {owner && (
              <div className='w-5'>
                <h3>Owner</h3>
                <p>{owner}</p>
              </div>
            )}
            {due && (
              due && (
                <div>
                  <h3>Due</h3>
                  <p><DateValue date={due} /></p>
                </div>
              )
            )}
          </div>
        </div>
        <div>
          {_.compact(tags).map((tag) => (
            <Tag
              value={tag}
              className='mr-2'
              key={tag}
              style={{
                backgroundColor: themeColor
              }}
            />
          ))}
        </div>
      </Dialog>
    </>
  )
}

export default GoalPanel
