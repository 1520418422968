import { gql, useQuery } from '@apollo/client'
import { useEffect } from 'react'
import ApolloErrorRenderer from '../../components/ApolloErrorRenderer'
import Loading from '../../components/Loading'
import SimpleDashboardWidget from '../../components/SimpleDashboardWidget'
import ExternalLink from '../../components/ExternalLink'
import { Badge } from 'primereact/badge'

const GET_INVOICES = gql`query getWorkspace($companyUUID:ID!) {
  platformCompany(company_uuid:$companyUUID) {
    company_uuid
    company_name
    invoices {
      invoice_number
      invoice_date
      due_date
      po_number
      isPaid
      total
      balance_due
      pdf_url
    }
  }
}`

function isOverdue (invoice) {
  if (!invoice.due_date) return false
  if (invoice.isPaid) return false

  return new Date(invoice.due_date).getTime() < new Date().getTime()
}

function StatusBadge ({ invoice }) {
  if (invoice.isPaid) {
    return (<Badge severity='success' value='Paid' />)
  }

  if (isOverdue(invoice)) {
    return (<Badge severity='danger' value='Overdue' />)
  }

  return null
}

function InvoiceRecord ({ invoice }) {
  return (
    <div>
      <div>
        <strong>Invoice Number: {invoice.invoice_number}</strong>&nbsp;
        <StatusBadge invoice={invoice} />
      </div>
      <div>
        <small>
          Invoice Date: {invoice.invoice_date}<br />
          Due Date: {invoice.due_date}<br />
          Amount: {invoice.total}<br />
          Balance Due: {invoice.balance_due}<br />
          {invoice.po_number && (
            <><span>PO Number: {invoice.po_number}</span><br /></>
          )}
          {/* these will only appear on prod because the dev system does not have einvoice generation enabled */}
          {invoice.pdf_url && (
            <ExternalLink href={invoice.pdf_url} download={`${invoice.invoice_number}.pdf`}>Download PDF</ExternalLink>
          )}
        </small>
      </div>
    </div>
  )
}

function InvoicesList ({ companyUUID }) {
  const { data, error, loading, refetch } = useQuery(GET_INVOICES, {
    variables: { companyUUID }
  })

  useEffect(() => {
    if (data && companyUUID && data.platformCompany.company_uuid !== companyUUID) {
      refetch({ variables: { companyUUID } })
    }
  }, [companyUUID, refetch, data])

  if (error) return <ApolloErrorRenderer error={error} />

  if (loading || !data) return <Loading />

  // const invoices = data.platformCompany.invoices
  const invoices = [{
    invoice_number: '2019-0001',
    invoice_date: '2019-01-01',
    due_date: '2019-01-31',
    po_number: 'PO-0001',
    isPaid: false,
    total: 100.00,
    balance_due: 100.00,
    pdf_url: 'https://www.google.com'
  }]

  return (
    <SimpleDashboardWidget title='Invoices'>
      {invoices.map((inv, idx) => <InvoiceRecord key={idx} invoice={inv} />)}
    </SimpleDashboardWidget>
  )
}

export default InvoicesList
