import * as Yup from 'yup'
import _ from 'lodash'

const INTEGRATION_IDENTIFIER_REGEX = /^[a-z0-9]+\.(?:[a-z0-9]+_)+(?:[a-z0-9]+)$/i

const SET_SKU_SCHEMA = Yup.object({
  sku: Yup.string().min(1).required(),
  name: Yup.string().min(1),
  description: Yup.string().min(1),
  detailed_description: Yup.string().optional().nullable(),
  price: Yup.number().min(0),
  allowed_licenses: Yup.array().of(Yup.string().min(1)),
  transaction_count: Yup.number().min(0),
  info_url: Yup.string().optional().nullable(),
  hidden: Yup.boolean().optional().nullable(),
  line_icon: Yup.string().optional().nullable(),
  flows_allowed: Yup.array().of(
    Yup.object({
      flow_type: Yup.string().min(1),
      integration_identifier: Yup.string().required().matches(INTEGRATION_IDENTIFIER_REGEX),
      name: Yup.string().min(1),
      description: Yup.string().min(1),
      source_file_types: Yup.array().of(Yup.string().min(1)),
      destination_file_types: Yup.array().of(Yup.string().min(1)),
      max_flows: Yup.number().min(0).integer()
    }).noUnknown()
  )
}).noUnknown()

const ADD_ADAPTERS_SCHEMA = Yup.object({
  integration_identifier: Yup.string().required().matches(INTEGRATION_IDENTIFIER_REGEX),
  source_file_types: Yup.array().min(1).of(
    Yup.string().min(1)
  ),
  destination_file_types: Yup.array().min(1).of(
    Yup.string().min(1)
  )
}).noUnknown()

function errToObj (err) {
  return _.pick(err, ['path', 'message'])
}

function getSchema (type) {
  if (type === 'set') {
    return SET_SKU_SCHEMA
  } else if (type === 'add_adapters') {
    return ADD_ADAPTERS_SCHEMA
  }
}

function customSKUValidator (type, sku) {
  try {
    const schema = getSchema(type)
    if (!schema) {
      return [`Invalid schema type ${type}`]
    }
    schema.validateSync(sku, { abortEarly: false, strict: true })
  } catch (err) {
    if (err.name === 'ValidationError') {
      const inner = err.inner
      if (inner.length === 0) {
        return [errToObj(err)]
      }
      return inner.map(errToObj)
    }
    throw err
  }
  return null
}

export default customSKUValidator
