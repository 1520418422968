import _ from 'lodash'
import { gql, useQuery, useMutation } from '@apollo/client'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ApolloErrorRenderer from '../../components/ApolloErrorRenderer'
import FieldListView from '../../components/FieldListView'
import Loading from '../../components/Loading'
import SimpleDashboardWidget from '../../components/SimpleDashboardWidget'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import WorkspacePortalLink from '../../components/WorkspacePortalLink'
import swal from 'sweetalert2'
import { Button } from 'primereact/button'

const GET_ADAPTER = gql`query openConnectAdapter($adapter_id: ID!) {
  openConnectAdapter(adapter_id: $adapter_id){
    adapter_id
    version_id
    display_name
    display_description
    support_url
    adapter_type
    registration_status
    integration_identifiers
    webhook_url
    publication_type
    adapter_icon_url
    denial_messages
    created_at
    updated_at
    workspace {
      company_uuid
      company_name
    }
    associated_workspaces {
      company_uuid
      company_name
    }
    workspace_associations
    configuration_options
    notification_emails
  }
}`

const APPROVE_ADAPTER = gql`mutation approveAdapter($input: ApproveOpenConnectAdapterInput) {
  approveOpenConnectAdapter(input: $input) {
    adapter_id
    registration_status
  }}`

const DENY_ADAPTER = gql`mutation denyAdapter($input: DenyOpenConnectAdapterInput) {
  denyOpenConnectAdapter(input: $input) {
    adapter_id
    registration_status
    denial_messages
  }}`

const BASE_LIST_ITEMS = [
  {
    title: 'Display Name',
    value: 'display_name'
  },
  {
    title: 'Owner Workspace',
    value: (data) => {
      if (!data.workspace) return ''
      return <WorkspacePortalLink companyUUID={data.workspace.company_uuid} name={data.workspace.company_name} />
    }
  },
  {
    title: 'Adapter ID',
    value: 'adapter_id',
    clipboard: true
  },
  {
    title: 'Version ID',
    value: 'version_id',
    clipboard: true
  },
  {
    title: 'Adapter Type',
    value: 'adapter_type'
  },
  {
    title: 'Publication Type',
    value: 'publication_type'
  },
  {
    title: 'Display Description',
    value: 'display_description'
  },
  {
    title: 'Webhook URL',
    value: 'webhook_url',
    clipboard: true
  },
  {
    title: 'Support URL',
    value: 'support_url',
    clipboard: true
  },
  {
    title: 'Adapter Icon URL',
    value: (data) => {
      if (!data.adapter_icon_url) return ''
      return <><img src={data.adapter_icon_url} alt={data.adapter_icon_url} width={50} />{data.adapter_icon_url}</>
    }
  },
  {
    title: 'Created At',
    value: 'created_at'
  },
  {
    title: 'Updated At',
    value: 'updated_at'
  },
  {
    title: 'Registration Status',
    value: 'registration_status'
  },
  {
    title: 'Integration Identifiers',
    value: 'integration_identifiers'
  },
  {
    title: 'Workspace Associations',
    value: (data) => {
      const workspaces = _.compact(data.associated_workspaces || [])
      if (workspaces.length === 0) {
        return ''
      }
      return workspaces.map((ws) => <div key={ws.company_uuid}><WorkspacePortalLink companyUUID={ws.company_uuid} name={ws.company_name} /></div>)
    }
  },
  {
    title: 'Denial Messages',
    value: (data) => {
      if (!data.denial_messages) return ''
      return data.denial_messages.map((msg) => <div key={msg}>{msg}</div>)
    }
  },
  {
    title: 'Notification Emails',
    value: (data) => {
      if (!data.notification_emails) return ''
      return data.notification_emails.map((email) => <div key={email}>{email}</div>)
    }
  },
  {
    title: 'Action',
    value: (data) => {
      if (data.registration_status === 'approved') return ''
      return (
        <div>
          <ApproveButton adapter={data} />  <DenyButton adapter={data} />
        </div>
      )
    }
  }

]

function ApproveButton ({ adapter }) {
  const [approveMutation, { loading, error }] = useMutation(APPROVE_ADAPTER)
  const doApprove = async () => {
    if (!window.confirm('Are you sure you want to approve this adapter?')) {
      return
    }

    approveMutation({
      variables: {
        input: {
          adapter_id: adapter.adapter_id
        }
      },
      refetchQueries: [GET_ADAPTER]
    })
  }
  useEffect(() => {
    if (!error) return
    window.alert(JSON.stringify(error))
    console.error(error)
  }, [error])

  if (adapter.registration_status !== 'under_review') {
    return null
  }
  return (
    <Button
      severity='success'
      onClick={doApprove}
      label='Approve'
      icon={<FontAwesomeIcon icon={loading ? faSpinner : faThumbsUp} spin={loading} className='mr-2' />}
    />
  )
}

function DenyButton ({ adapter }) {
  const [denyMutation, { loading, error }] = useMutation(DENY_ADAPTER)
  const doDeny = async () => {
    const { value: reason, isConfirmed } = await swal.fire({
      title: 'Deny Adapter',
      input: 'textarea',
      inputLabel: 'Reason for Denial',
      inputPlaceholder: 'Enter reason here...',
      inputAttributes: {
        'aria-label': 'Enter reason here'
      },
      inputValidator: (value) => {
        if (!value || _.trim(value) === '') {
          return 'You must enter a reason for denial'
        }
      },
      showCancelButton: true
    })

    if (!isConfirmed) {
      return
    }
    denyMutation({
      variables: {
        input: {
          adapter_id: adapter.adapter_id,
          denial_messages: [reason]
        }
      },
      refetchQueries: [GET_ADAPTER]
    })
  }
  useEffect(() => {
    if (!error) return
    window.alert(JSON.stringify(error))
    console.error(error)
  }, [error])

  if (adapter.registration_status !== 'under_review') {
    return null
  }
  return (
    <Button
      severity='danger'
      onClick={doDeny}
      label='Deny'
      icon={<FontAwesomeIcon icon={loading ? faSpinner : faThumbsDown} spin={loading} className='mr-2' />}
    />
  )
}

function AdapterView ({ adapter, title }) {
  const listItems = adapter.registration_status === 'under_review' ? BASE_LIST_ITEMS : BASE_LIST_ITEMS.filter((item) => item.title !== 'Action')
  return (
    <SimpleDashboardWidget title={title}>
      <FieldListView data={adapter} listDefinition={listItems} variant='flush' />
    </SimpleDashboardWidget>
  )
}

const processAdapter = (adapterRows) => {
  const unapprovedAdapter = _.last(_.sortBy(adapterRows.filter(a => a.registration_status === 'under_review'), 'version_id'))
  const approvedAdapter = _.last(_.sortBy(adapterRows.filter(a => a.registration_status === 'approved'), 'version_id'))
  const deniedAdapter = _.last(_.sortBy(adapterRows.filter(a => a.registration_status === 'denied'), 'version_id'))
  return {
    ...(approvedAdapter || unapprovedAdapter || deniedAdapter),
    unapprovedAdapter,
    approvedAdapter,
    deniedAdapter
  }
}

function View () {
  const params = useParams()

  const { loading, error, data } = useQuery(GET_ADAPTER, { variables: { adapter_id: params.adapter_id } })

  if (error) return <ApolloErrorRenderer error={error} />

  if (loading || !data) return <Loading />

  const adapterRows = data.openConnectAdapter
  const a = processAdapter(adapterRows)
  const nonApproved = a.unapprovedAdapter || a.deniedAdapter
  const nonApprovedTitle = a.unapprovedAdapter ? 'Under Review' : 'Denied'
  const diffStyles = {
    line: {
      wordBreak: 'break-word'
    }
  }

  return (
    <div className='p2'>
      <h1>Adapter: {a.display_name}</h1>
      <div className='grid'>
        <div className='col-12 md:col-6'>
          {a.approvedAdapter && <AdapterView adapter={a.approvedAdapter} title='Approved' />}
        </div>
        <div className='col-12 md:col-6'>
          {nonApproved && <AdapterView adapter={nonApproved} title={nonApprovedTitle} />}
        </div>
      </div>
      <div className='mt-4'>
        <h3>Diff</h3>
        <ReactDiffViewer
          oldValue={JSON.stringify(a.approvedAdapter, null, 2)}
          newValue={JSON.stringify(nonApproved, null, 2)}
          compareMethod={DiffMethod.WORDS} leftTitle='Approved'
          rightTitle={nonApprovedTitle}
          styles={diffStyles}
          splitView
        />
      </div>
    </div>
  )
}

export default View
