import { gql, useQuery } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'
import { NumericFormat } from 'react-number-format'
import ApolloErrorRenderer from '../../components/ApolloErrorRenderer'
import Loading from '../../components/Loading'
import { useEffect, useState } from 'react'
import SimpleDashboardWidget from '../../components/SimpleDashboardWidget'
import FieldListView from '../../components/FieldListView'
import PipedriveLink from '../../components/PipedriveLink'
import MaxioLink from '../../components/MaxioLink'
import ZendeskOrganizationLink from '../../components/ZendeskOrganizationLink'
import RiskBadge from '../../components/RiskBadge'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { DateTime } from 'luxon'

// TODO UPDATE THE BACKEND TO ALLOW FOR SINGLE QUERY
const GET_CUSTOMERS = gql`query getDetailedCustomer($customerID:String!) {
  customer(customerID:$customerID) {
    company {
      company_name
    }
    company_uuid
    email
    name
    saasoptics_id
    pipedrive_id
    zendesk_id
    pipedriveOrganization {
      name
      owner {
        name
      }
      client_success_manager {
        name
      }
      pinned_notes {
        content
        id
      }
      risk
      website
    }
    zendeskOrganization {
        domain_names
        id
        name
        notes
        open_tickets
    }

    contracts {
      id
      number
      email
      entry_date
      transactions {
        end_date
        home_amount
        id
        invoice_description
        notes
        order_date
        quantity
        renewal_amount
        start_date
        item_id
        renewal_of_set
        home_arr_amount
        item {
          code
          id
          is_active
          name
          recurring
        }
      }
    }
  }
}`
const ZENDESK_ITEMS = [
  {
    title: 'Zendesk Link',
    value: (customer) => <ZendeskOrganizationLink customerID={customer.zendesk_id} />
  },
  {
    title: 'Organization Name',
    value: 'zendeskOrganization.name',
    clipboard: true
  },
  {
    title: 'Open Tickets',
    value: 'zendeskOrganization.open_tickets'
  },
  {
    title: 'Notes',
    value: 'zendeskOrganization.notes',
    clipboard: true
  },
  {
    title: 'Domain Names',
    value: (c) => (c.zendeskOrganization?.domain_names || []).map((d) => <div key={d}>{d}</div>)
  }
]

const ACCOUNT_OWNER_ITEMS = [
  {
    title: 'Account Executive',
    value: 'pipedriveOrganization.owner.name'
  },
  {
    title: 'Client Success Manager',
    value: 'pipedriveOrganization.client_success_manager.name'
  }
]

const LINK_ITEMS = [
  {
    title: 'Maxio Link',
    value: (customer) => <MaxioLink customerID={customer.saasoptics_id} />
  },
  {
    title: 'Pipedrive Link',
    value: (customer) => <PipedriveLink customer={customer} />
  },
  {
    title: 'Workspace',
    value: (customer) => !customer.company_uuid ? null : <Link to={`/workspaces/${customer.company_uuid}`}>{customer.company?.company_name}</Link>
  },
  {
    title: 'Support Tickets',
    value: (customer) => <ZendeskOrganizationLink customerID={customer.zendesk_id} />
  }
]

const ACCOUNT_DETAILS_ITEMS = [
  {
    title: 'Risk',
    value: (customer) => <RiskBadge risk={customer.pipedriveOrganization?.risk} />
  },
  {
    title: 'Website',
    value: 'pipedriveOrganization.website',
    clipboard: true
  }
]

function activeARR (customer) {
  const lastOfNextMonth = DateTime.utc().plus({ months: 1 }).endOf('month').toMillis()
  const transactions = customer.contracts.reduce((acc, contract) => {
    return acc.concat(contract.transactions)
  }, [])
  const active = transactions.filter(t => t.item?.recurring).filter((t) => DateTime.fromISO(t.start_date).toMillis() < lastOfNextMonth).filter(t => DateTime.fromISO(t.end_date).toMillis() > DateTime.utc().toMillis())
  const transactionsMappedByID = active.reduce((acc, t) => {
    acc[t.id.toString()] = t
    return acc
  }, {})
  const withoutDupes = active.filter((t) => {
    const renewalSet = t.renewal_of_set
    if (!renewalSet) return true
    for (const r of renewalSet) {
      // don't include renewals if the original transaction is still active
      if (transactionsMappedByID[r.toString()]) return false
    }
    return true
  })
  return withoutDupes.reduce((acc, t) => acc + (t.home_arr_amount || 0), 0)
}

const MAXIO_ITEMS = [
  {
    title: 'Maxio ID',
    value: 'saasoptics_id',
    clipboard: true
  },
  {
    title: 'Active ARR',
    value: (customer) => <Currency value={activeARR(customer)} />
  },
  {
    title: 'Email',
    value: 'email',
    clipboard: true
  },
  {
    title: 'Maxio - Pipedrive ID',
    value: 'pipedrive_id',
    clipboard: true
  }
]

function PipedriveNotes ({ notes }) {
  if (!notes || notes.length === 0) {
    return (
      <div className='text-muted p-4'>No notes found.</div>
    )
  }
  return (
    <>
      {notes.map((note) => (
        <div key={note.id} className='mb-2 p-2'>
          <div dangerouslySetInnerHTML={{ __html: note.content }} />
          <hr />
        </div>
      ))}
    </>
  )
}

function Currency ({ value }) {
  return (
    <NumericFormat
      value={value}
      displayType='text'
      thousandSeparator
      prefix='$'
    />
  )
}

function TransactionDescription ({ transaction }) {
  const [show, setShow] = useState(false)
  const descLength = transaction.invoice_description?.length || 0
  const maxLength = 50
  return (
    <>
      <span>{transaction.invoice_description?.slice(0, maxLength)}</span>
      {descLength > maxLength && (
        <>
          ...
          <Button
            size='sm'
            link
            label='more'
            className='ml-2'
            onClick={() => setShow(true)}
          />
        </>
      )}
      <Dialog
        header='Invoice Description'
        visible={show}
        onHide={() => setShow(false)}
        style={{ width: '50vw' }}
        dismissableMask
      >
        <div className='p-2'>
          {transaction.invoice_description}
        </div>
      </Dialog>
    </>
  )
}

function Transactions ({ customer }) {
  const transactions = customer.contracts.flatMap((contract) => contract.transactions)
  return (
    <DataTable
      value={transactions}
      className='p-datatable-sm'
      paginator
      rows={20}
      stripedRows
      rowHover
      resizableColumns
      sortField='end_date'
      sortOrder={-1}
    >
      <Column sortable filter field='item.name' header='Item' />
      <Column sortable filter field='invoice_description' header='Invoice Description' body={t => <TransactionDescription transaction={t} />} />
      <Column field='quantity' header='Quantity' />
      <Column sortable field='order_date' header='Order Date' />
      <Column sortable field='start_date' header='Start Date' />
      <Column sortable field='end_date' header='End Date' />
      <Column sortable field='home_amount' header='Home Amount' body={(rowData) => <Currency value={rowData.home_amount} />} />
      <Column field='renewal_amount' header='Renewal Amount' body={(rowData) => <Currency value={rowData.renewal_amount} />} />
      <Column field='renewal_of_set' header='Renewals Transaction' />
      <Column field='notes' header='Notes' />
      <Column field='contract.number' header='Contract' />
      <Column field='id' header='Transaction ID' />
    </DataTable>
  )
}

function View () {
  const params = useParams()
  const [customer, setCustomer] = useState(null)

  const { loading, error, data } = useQuery(GET_CUSTOMERS, {
    variables: {
      customerID: params.saasoptics_id
    }
  })

  useEffect(() => {
    console.log('customer', customer)
    console.log('params', params)
    if (customer?.saasoptics_id.toString() === params.saasoptics_id) return
    if (data) {
      console.log('data', data)
      setCustomer(data.customer)
    }
  }, [data, params, customer])

  if (error) return <ApolloErrorRenderer error={error} />

  if (loading || !customer) return <Loading fullPage />

  return (
    <div className='p-2'>
      <h1>Customer: {customer.name}</h1>
      <div className='grid'>
        <div className='col-12 md:col-6'>
          <SimpleDashboardWidget title='Account Owners' className='mb-2'>
            <FieldListView
              data={customer}
              listDefinition={ACCOUNT_OWNER_ITEMS}
              variant='flush'
            />
          </SimpleDashboardWidget>
          <SimpleDashboardWidget title='Links'>
            <FieldListView
              data={customer}
              listDefinition={LINK_ITEMS}
              variant='flush'
            />
          </SimpleDashboardWidget>
          <SimpleDashboardWidget title='Zendesk'>
            <FieldListView
              data={customer}
              listDefinition={ZENDESK_ITEMS}
              variant='flush'
            />
          </SimpleDashboardWidget>
        </div>
        <div className='col-12 md:col-6'>
          <SimpleDashboardWidget title='Account Details'>
            <FieldListView
              data={customer}
              listDefinition={ACCOUNT_DETAILS_ITEMS}
              variant='flush'
            />
          </SimpleDashboardWidget>
          <SimpleDashboardWidget title='Pipedrive Pinned Notes'>
            <PipedriveNotes notes={customer.pipedriveOrganization?.pinned_notes} />
          </SimpleDashboardWidget>
          <SimpleDashboardWidget title='Maxio Data'>
            <FieldListView
              data={customer}
              listDefinition={MAXIO_ITEMS}
              variant='flush'
            />
          </SimpleDashboardWidget>
        </div>
      </div>
      <h2>Transactions</h2>
      <Transactions customer={customer} />
    </div>
  )
}

export default View
