import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'primereact/button'

function CopyButton ({
  text, size = 'small', className = ''
}) {
  return (
    <CopyToClipboard text={text}>
      <Button
        style={{ color: 'lightgray', padding: '0.25rem' }}
        className={className}
        link
        title='copy to clipboard'
        size={size}
        disabled={!text || text.length === 0}
        icon={<FontAwesomeIcon icon={faCopy} />}
      />
    </CopyToClipboard>
  )
}

export default CopyButton
