function CurrencyView ({ value, currency = 'USD' }) {
  if (!value && value !== 0) return null
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol'
  }).format(value)
}

export default CurrencyView
