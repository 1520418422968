import React from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'

import 'primereact/resources/themes/bootstrap4-light-blue/theme.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'

import 'react-datetime/css/react-datetime.css'
import AppRouter from './components/AppRouter'
import FallbackComponent from './components/FallbackComponent'

import './index.scss'

Sentry.init({
  dsn: 'https://61dbe8cd8d6c48dd8cdeddcf5d86dcae@o101912.ingest.sentry.io/5932757',
  environment: import.meta.env.VITE_CHAINIO_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0
})

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<FallbackComponent />}>
      <AppRouter />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
)
