import { Badge } from 'primereact/badge'

const RISK_VARIANTS = {
  'No Live Integrations': 'info',
  Green: 'success',
  Yellow: 'warning',
  Red: 'danger'
}

function RiskBadge ({ risk, end }) {
  if (!risk) return null
  return (
    <Badge
      severity={RISK_VARIANTS[risk] || null}
      style={RISK_VARIANTS[risk] ? {} : { backgroundColor: 'black' }}
      value={
        <>
          {risk}
          {
          end &&
            <span className='pl-1'>{end}</span>
          }
        </>
}
    />
  )
}

export default RiskBadge
