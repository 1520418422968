import { Card } from 'primereact/card'

function SimpleDashboardWidget ({ title, children }) {
  return (
    <Card className='my-2' title={title}>
      {children}
    </Card>
  )
}

export default SimpleDashboardWidget
