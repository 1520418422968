import Papa from 'papaparse'
import xpath from 'xpath'
import { DOMParser } from '@xmldom/xmldom'

/**
 * this strips namespaces
 *
 * expected test file format (must have header row)
 *
 * if a row has anyMatch set to true, then the string matching will pass if ANY matching node
 * meets the critera, else it will only match if ALL strings match the criteria
 *
 * xpath,value,resultCount,anyMatch
 * //mypath,hello world,1,true
 * //otherpath,,1
 * //some/path,value
 */

function testResultCount (nodes, test, testNumber) {
  if (test.resultCount && test.resultCount.length > 0) {
    let rC
    try {
      rC = parseInt(test.resultCount, 10)
    } catch (err) {
      throw new Error(`Test ${testNumber} has an invalid result count value "${test.resultCount}`)
    }
    if (nodes.length !== rC) {
      return `Test ${testNumber}: Expected ${rC} results, found ${nodes.length} for xpath ${test.xpath}`
    }
  }
  return null
}

function testValue (nodes, test, testNumber) {
  if (!test.value || test.value === '') return null
  const totalNodes = nodes.length
  if (totalNodes === 0) {
    return `Test ${testNumber}: Expected to find ${test.value}, but xpath was not found ${test.xpath}`
  }
  const misMatches = nodes.map((n, idx) => {
    const val = n.firstChild.data
    if (val === test.value) return null
    return `Test ${testNumber}: Expected ${test.value} and found ${val} at xpath instance ${idx + 1}: ${test.xpath}`
  }).filter(x => x)
  if (test.anyMatch === 'true' && totalNodes > misMatches.length) {
    return null
  }
  if (misMatches.length > 0) {
    return misMatches.join('\n')
  }
  return null
}

const TEST_FUNCTIONS = [
  testResultCount,
  testValue
]

function cleanNamespace (xmlStr) {
  return xmlStr.replace(/ xmlns(:.*){0,1}="[^"]*"/, '')
}

function run (xmlStr, testStr) {
  const cleanXml = cleanNamespace(xmlStr)
  const doc = new DOMParser().parseFromString(cleanXml)
  const tests = Papa.parse(testStr, { header: true }).data.filter(x => x.xpath && x.xpath.match(/\S/))
  try {
    const errors = tests.map((t, idx) => {
      const row = idx + 1
      if (!t.xpath) throw new Error(`Test ${row}: does not have xpath`)
      let nodes
      try {
        nodes = xpath.select(t.xpath, doc)
      } catch (error) {
        return `Test ${row}: Xpath error (${t.xpath}): ${error.message}`
      }
      const testResults = TEST_FUNCTIONS.map(tf => tf(nodes, t, row))
      const errorResults = testResults.filter(x => x)
      if (errorResults.length > 0) {
        return errorResults.join('\n')
      }
      return null
    }).filter(x => x)
    if (errors.length > 0) {
      return `🚨 ${errors.length} errors\n${errors.join('\n')}`
    }
    return `🙌 Ran ${tests.length} test${tests.length > 1 ? 's' : ''} and didn't find any errors.`
  } catch (err) {
    return `🚨 ${err.message}`
  }
}

export default run
