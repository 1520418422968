import { NetworkStatus, gql, useQuery } from '@apollo/client'
import ApolloErrorRenderer from './ApolloErrorRenderer'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'

const GET_NOTES = gql`query getDetailedCustomer($customerID:String!) {
  customer(customerID:$customerID) {
    company_uuid
    pipedriveOrganization {
      pinned_notes {
          id
          content
      }
    }
  }
}`

function PipedriveNotesPane ({ customer }) {
  const [showModal, setShowModal] = useState(false)
  const { loading, error, data, refetch, networkStatus } = useQuery(GET_NOTES, {
    variables: {
      customerID: customer.saasoptics_id.toString()
    },
    notifyOnNetworkStatusChange: true
  })

  const doRefetch = () => {
    refetch({ customerID: customer.saasoptics_id.toString() })
  }

  if (error) return <ApolloErrorRenderer error={error} />

  const isLoading = loading || networkStatus === NetworkStatus.refetch

  const notes = isLoading ? null : data?.customer?.pipedriveOrganization?.pinned_notes

  return (
    <>
      <Button
        onClick={() => setShowModal(true)}
        disabled={isLoading}
        size='small'
        link
        text
        label='Pipedrive Notes'
        icon={<FontAwesomeIcon icon={isLoading ? faSpinner : faEye} spin={isLoading} className='mr-1' />}
      />
      <Dialog
        header={`Pipedrive Notes for ${customer.name}`}
        visible={showModal}
        onHide={() => setShowModal(false)}
        style={{ width: '80vw' }}
        maximizable
        dismissableMask
        footer={
          <Button
            onClick={doRefetch}
            disabled={isLoading}
            label='Refresh'
            icon={isLoading && <FontAwesomeIcon icon={faSpinner} spin className='mr-1' />}
          />
        }
      >
        {isLoading && <p>Loading...</p>}
        {!isLoading && (!notes || notes.length === 0) && <p>No notes found.</p>}
        {notes?.map((note, idx) => (
          <div key={note.id}>
            <div dangerouslySetInnerHTML={{ __html: note.content }} />
          </div>
        ))}
      </Dialog>
    </>
  )
}

export default PipedriveNotesPane
