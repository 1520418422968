import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default function ExternalLink (props) {
  const fullProps = {
    ...props,
    target: '_blank'
  }
  return (
    <>
      <a {...fullProps}>{props.children}</a> <span style={{ color: 'lightgray' }}><FontAwesomeIcon icon={faExternalLinkAlt} /></span>
    </>
  )
}
