import { useRouteError } from 'react-router-dom'
import FallbackComponent from './FallbackComponent'
import * as Sentry from '@sentry/react'

function TopLevelErrorRenderer () {
  const err = useRouteError()

  console.error(err)

  Sentry.captureException(err)

  return <FallbackComponent />
}

export default TopLevelErrorRenderer
