import _ from 'lodash'
import React, { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import ApolloErrorRenderer from '../../components/ApolloErrorRenderer'
import Loading from '../../components/Loading'
import portalUrl from '../../utils/portalUrl'
import DateValue from '../../components/DateValue'
import { Link } from 'react-router-dom'
import CSVDownload from '../../components/CSVDownload'
import ExternalLink from '../../components/ExternalLink'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import CopyableUUID from '../../components/CopyableUUID'
import { InputText } from 'primereact/inputtext'

const GET_FLOWS = gql`query getFlows {
  platformFlows {
    company {
      company_name
      company_uuid
    }
    tradingPartner {
      partner_uuid
      partner_name
    }
    flow_uuid
    flow_name
    flow_type
    disabled
    source_name
    source_type
    source_file_type
    destination_type
    destination_file_type
    destination_name
    created_date
    updated_date
    last_executed_date: executed_date
  }
}`

function FlowNameLink ({ flow }) {
  if (!flow.company || !flow.tradingPartner) return flow.flow_name
  return (
    <ExternalLink href={portalUrl(`/c/${flow.company.company_uuid}/settings/tp/${flow.tradingPartner.partner_uuid}/fc/${flow.flow_uuid}`)}>
      {flow.flow_name}
    </ExternalLink>
  )
}

function disabledDisplayValue (disabled) {
  return disabled === true ? 'true' : 'false'
}

function csvRow (flow) {
  return [
    flow.flow_name,
    flow.company?.company_name,
    `/workspaces/${flow.company.company_uuid}`,
    flow.tradingPartner?.partner_name,
    flow.flow_type,
    flow.source_name,
    flow.source_type,
    flow.source_file_type,
    flow.destination_name,
    flow.destination_type,
    flow.destination_file_type,
    flow.created_date,
    flow.updated_date,
    flow.last_executed_date,
    disabledDisplayValue(flow.disabled),
    flow.flow_uuid
  ]
}

const CSV_HEADINGS = [
  'Flow Name',
  'Workspace Name',
  'Workspace Link',
  'Integration Name',
  'Flow Type',
  'Source Name',
  'Source Type',
  'Source File Type',
  'Destination Name',
  'Destination Type',
  'Destination File Type',
  'Created At',
  'Updated At',
  'Last Executed',
  'Disabled',
  'Flow UUID'

]

function FlowList ({ data }) {
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const uniqueFlows = _.keyBy(data.platformFlows, 'flow_uuid')

  // public tracking flows don't have a trading partner
  const flows = _.values(uniqueFlows).map(f => {
    return {
      ...f,
      tradingPartner: f.tradingPartner || {}
    }
  })

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    setGlobalFilterValue(value)
  }

  const rowClass = (data) => {
    return {
      'line-through': data.disabled,
      'text-muted': data.disabled
    }
  }

  return (
    <>
      <div className='flex justify-content-between flex-wrap mb-3'>
        <CSVDownload
          fileName='flows.csv'
          records={flows}
          recordToStringArrayFn={csvRow}
          headings={CSV_HEADINGS}
          className='flex'
        />
        <div className='flex justify-content-end'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder='Keyword Search' />
          </span>
        </div>
      </div>
      <DataTable
        value={flows}
        stripedRows
        rowHover
        resizableColumns
        size='small'
        sortField='flow_name'
        sortOrder={1}
        paginator
        rows={50}
        globalFilter={globalFilterValue}
        rowClassName={rowClass}
        dataKey='flow_uuid'
        scrollable
        scrollHeight='65vh'
        globalFilterFields={['flow_name', 'company.company_name', 'tradingPartner.partner_name', 'flow_type', 'source_name', 'source_type', 'source_file_type', 'destination_name', 'destination_type', 'destination_file_type', 'flow_uuid']}
      >
        <Column sortable filter header='Name' field='flow_name' body={f => <FlowNameLink flow={f} />} />
        <Column sortable filter field='company.company_name' header='Workspace' body={f => <Link to={`/workspaces/${f.company.company_uuid}`}>{f.company.company_name}</Link>} />
        <Column sortable filter field='tradingPartner.partner_name' header='Integration' body={f => <ExternalLink href={portalUrl(`/c/${f.company.company_uuid}/settings/tp/${f.tradingPartner.partner_uuid}`)}>{f.tradingPartner.partner_name}</ExternalLink>} />
        <Column sortable filter field='flow_type' header='Flow Type' />
        <Column sortable filter field='source_name' header='Source Name' />
        <Column sortable filter field='source_type' header='Source Type' />
        <Column sortable filter field='source_file_type' header='Source File Type' />
        <Column sortable filter field='destination_name' header='Destination Name' />
        <Column sortable filter field='destination_type' header='Destination Type' />
        <Column sortable filter field='destination_file_type' header='Destination File Type' />
        <Column sortable filter field='created_date' header='Created' body={f => <DateValue date={f.created_date} />} />
        <Column sortable filter field='updated_date' header='Updated' body={f => <DateValue date={f.updated_date} />} />
        <Column sortable filter field='last_executed_date' header='Last Executed' body={f => <DateValue date={f.last_executed_date} />} />
        <Column sortable filter field='disabled' header='Disabled?' body={f => disabledDisplayValue(f.disabled)} />
        <Column sortable filter field='flow_uuid' header='Flow UUID' body={f => <CopyableUUID uuid={f.flow_uuid} />} />
      </DataTable>
    </>
  )
}

function List () {
  const { loading, error, data } = useQuery(GET_FLOWS)

  if (error) return <ApolloErrorRenderer error={error} />

  if (loading || !data) return <Loading fullPage />

  return (
    <>
      <h1>Flows</h1>
      <FlowList data={data} />
    </>
  )
}

export default List
