import ExternalLink from './ExternalLink'

function ZendeskOrganizationLink ({ customerID }) {
  if (!customerID || customerID === '') return null
  return (
    <ExternalLink
      href={`https://chainio.zendesk.com/agent/organizations/${customerID}/tickets`}
      target='_blank'
      rel='noreferrer'
    >
      Zendesk Organization
    </ExternalLink>
  )
}

export default ZendeskOrganizationLink
