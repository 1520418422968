import CopyButton from './CopyButton'

function CopyableUUID ({ uuid }) {
  if (!uuid) return null
  return (
    <>
      <small>{uuid}</small><CopyButton text={uuid} />
    </>
  )
}

export default CopyableUUID
