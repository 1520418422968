import ExternalLink from './ExternalLink'

function PipedriveLink ({ customer }) {
  if (!customer.pipedrive_id) return null
  return (
    <ExternalLink href={`https://chainio.pipedrive.com/organization/${customer.pipedrive_id}`} target='blank' rel='noreferrer'>{customer.pipedriveOrganization?.name || customer.pipedrive_id}</ExternalLink>
  )
}

export default PipedriveLink
