import { gql, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import ApolloErrorRenderer from '../../components/ApolloErrorRenderer'
import FieldListView from '../../components/FieldListView'
import Loading from '../../components/Loading'
import SimpleDashboardWidget from '../../components/SimpleDashboardWidget'
import CopyButton from '../../components/CopyButton'

const GET_EXECUTION = gql`query getEmbeddedExecution($id: ID!) {
  embeddedExecution(execution_id: $id) {
    execution_id
    status
    client_request_id
    start_time
    end_time
    environment
    client_request {
      method
      path
      ip
      operation_id
      body
      headers {
        id
        value
      }
      query_string {
        id
        value
      }
    }
    connection {
      connection_id
      server_type
      client_owner_id
      client_id
    }
    client_response
    status_code
    lambda {
      name
      start
      end
      duration
      request_id
    }
    internal_error
  }
}`

const HEADER_FIELDS = [
  {
    title: 'Execution ID',
    value: 'execution_id',
    clipboard: true
  },
  {
    title: 'Operation ID',
    value: 'client_request.operation_id'
  },
  {
    title: 'Start Time',
    value: 'start_time',
    clipboard: true
  },
  {
    title: 'End Time',
    value: 'end_time',
    clipboard: true
  }
]
const CLIENT_FIELDS = [
  {
    title: 'Client Request ID',
    value: 'client_request_id',
    clipboard: true
  },
  {
    title: 'Request Method',
    value: 'client_request.method'
  },
  {
    title: 'Request Path',
    value: 'client_request.path',
    clipboard: true
  },
  {
    title: 'Request IP',
    value: 'client_request.ip',
    clipboard: true
  },
  {
    title: 'Environment',
    value: 'environment'
  }
]

const CONNECTION_FIELDS = [
  {
    title: 'Connection ID',
    value: 'connection.connection_id',
    clipboard: true
  },
  {
    title: 'Server Type',
    value: 'connection.server_type'
  },
  {
    title: 'Client ID',
    value: 'connection.client_id',
    clipboard: true
  },
  {
    title: 'Client Owner ID',
    value: 'connection.client_owner_id',
    clipboard: true
  }
]

const LAMBDA_FIELDS = [
  {
    title: 'Name',
    value: 'lambda.name',
    clipboard: true
  },
  {
    title: 'Start',
    value: 'lambda.start'
  },
  {
    title: 'End',
    value: 'lambda.end'
  },
  {
    title: 'Duration',
    value: 'lambda.duration'
  },
  {
    title: 'Request ID',
    value: 'lambda.request_id',
    clipboard: true
  }
]

function RequestHeadersList ({ execution }) {
  const headers = _.get(execution, 'client_request.headers', [])
  return (
    headers.map((h, idx) => (
      <div className='py-2' key={idx}>
        <div><small>{h.id}</small></div>
        <div>
          <code>{h.value}</code>
          <CopyButton text={h.value} className='ml-1' />
        </div>
      </div>
    ))
  )
}

function ResponseView ({ execution }) {
  let resp = _.get(execution, 'client_response') || 'null'
  try {
    while (resp) {
      resp = JSON.parse(resp)
    }
  } catch (ignoreMe) {
  // do nothing, keep looping until we get to the object
  }
  return JSON.stringify(resp, null, 2)
}

function View () {
  const params = useParams()

  const { loading, error, data } = useQuery(GET_EXECUTION, {
    variables: { id: params.execution_id }
  })

  if (error) return <ApolloErrorRenderer error={error} />

  if (loading || !data) return <Loading />

  const { embeddedExecution: ex } = data

  if (!ex) return (<h1>Execution {params.execution_id} not found </h1>)

  return (
    <div className='p-2'>
      <h1>Embedded Execution</h1>
      <SimpleDashboardWidget>
        <FieldListView data={ex} listDefinition={HEADER_FIELDS} />
      </SimpleDashboardWidget>
      <div className='grid'>
        <div className='col-12 md:col-6'>
          <SimpleDashboardWidget title='Client'>
            <FieldListView data={ex} listDefinition={CLIENT_FIELDS} />
          </SimpleDashboardWidget>
          <SimpleDashboardWidget title='Lambda'>
            <FieldListView data={ex} listDefinition={LAMBDA_FIELDS} />
          </SimpleDashboardWidget>
        </div>
        <div className='col-12 md:col-6'>
          <SimpleDashboardWidget title='Connection'>
            <FieldListView data={ex} listDefinition={CONNECTION_FIELDS} />
          </SimpleDashboardWidget>
          <SimpleDashboardWidget title='Request Headers'>
            <RequestHeadersList execution={ex} />
          </SimpleDashboardWidget>
        </div>
      </div>
      <SimpleDashboardWidget title='Request Body'>
        <pre>{_.get(ex, 'client.body') || 'null'}</pre>
      </SimpleDashboardWidget>
      <SimpleDashboardWidget title='Response Body (formatted)'>
        <pre><ResponseView execution={ex} /></pre>
      </SimpleDashboardWidget>
    </div>
  )
}

export default View
