import React, { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import ApolloErrorRenderer from '../../components/ApolloErrorRenderer'
import Loading from '../../components/Loading'
import IntegrationPortalLink from '../../components/IntegrationPortalLink'
import DateValue from '../../components/DateValue'
import { Link } from 'react-router-dom'
import CSVDownload from '../../components/CSVDownload'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import CopyableUUID from '../../components/CopyableUUID'
import { InputText } from 'primereact/inputtext'

const CSV_HEADINGS = [
  'Integration',
  'Workspace',
  'Notes',
  'Created',
  'Updated',
  'Integration UUID'
]

function csvRow (integration) {
  return [
    integration.partner_name,
    integration.company?.company_name,
    integration.notes,
    integration.created_date,
    integration.updated_date,
    integration.partner_uuid
  ]
}

const GET_INTEGRATIONS = gql`query getIntegrations {
  platformPartners {
    company_uuid
    partner_uuid
    partner_name
    notes
    created_date
    updated_date
    company {
      company_name
      environment_type
    }
  }
}`

function IntegrationsList ({ data }) {
  const [globalFilterValue, setGlobalFilterValue] = useState('')

  const integrations = data.platformPartners
  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    setGlobalFilterValue(value)
  }
  return (
    <>
      <div className='flex justify-content-between flex-wrap mb-3'>
        <CSVDownload
          fileName='integrations.csv'
          records={integrations}
          recordToStringArrayFn={csvRow}
          headings={CSV_HEADINGS}
        />
        <div className='flex justify-content-end'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder='Keyword Search' />
          </span>
        </div>
      </div>
      <DataTable
        value={integrations}
        stripedRows
        rowHover
        resizableColumns
        size='small'
        paginator
        rows={50}
        sortField='company.company_name'
        sortOrder={1}
        globalFilter={globalFilterValue}
        dataKey='partner_uuid'
        globalFilterFields={['partner_name', 'company.company_name', 'notes', 'partner_uuid']}
      >
        <Column
          sortable
          filter
          header='Integration'
          field='partner_name'
          body={integration => (
            <IntegrationPortalLink
              companyUUID={integration.company_uuid}
              tradingPartnerUUID={integration.partner_uuid}
              name={integration.partner_name}
            />
          )}
        />
        <Column
          sortable
          filter
          field='company.company_name'
          header='Workspace'
          body={i => <Link to={`/workspaces/${i.company_uuid}`}>{i.company?.company_name}</Link>}
        />
        <Column filter header='Notes' field='notes' />
        <Column sortable field='created_date' header='Created' body={i => <DateValue date={i.created_date} />} />
        <Column sortable field='updated_date' header='Updated' body={i => <DateValue date={i.updated_date} />} />
        <Column sortable filter field='partner_uuid' header='Integration UUID' body={i => <CopyableUUID uuid={i.partner_uuid} />} />
      </DataTable>
    </>
  )
}

function List () {
  const { loading, error, data } = useQuery(GET_INTEGRATIONS)

  if (error) return <ApolloErrorRenderer error={error} />

  if (loading || !data) return <Loading fullPage />

  return (
    <>
      <h1>Integrations</h1>
      <IntegrationsList data={data} />
    </>
  )
}

export default List
