import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import ApolloErrorRenderer from '../components/ApolloErrorRenderer'
import Loading from '../components/Loading'
import FieldListView from '../components/FieldListView'
import { useEffect, useState } from 'react'
import CurrencyView from '../components/CurrencyView'
import ExternalLink from '../components/ExternalLink'
import { Card } from 'primereact/card'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'

const GET_LICENSE_TYPES = gql`query getLicenseTypes {
  licenseDefinitions {
    workspaceLicenseTypes {
      sku
      name
      description
      price
      info_url
      allowed_integration_license_types {
        sku
        name
      }
    }
    integrationLicenseTypes {
      sku
      name
      description
      price
      allowed_licenses
      transaction_count
      info_url
      hidden
      line_icon
      flows_allowed {
        flow_type
        name
        description
        source_file_types {
          label
          code
          description
          is_public
        }
        destination_file_types {
          label
          code
          description
          is_public
        }
        max_flows
      }
    }
  }
}`

function IntegrationLicenseLink ({ ilt }) {
  return (<li><a href={`#${ilt.sku}`}>{ilt.name}</a></li>)
}

const WORKSPACE_FIELDS = [
  {
    title: 'SKU',
    value: 'sku'
  },
  {
    title: 'Description',
    value: 'description'
  },
  {
    title: 'Price',
    value: (license) => <CurrencyView value={license.price} />
  },
  {
    title: 'Marketing URL',
    value: (license) => license.info_url ? (<ExternalLink href={license.info_url}>Website</ExternalLink>) : null
  },
  {
    title: 'SKUs',
    value: (license) => (<ul>{_.sortBy(license.allowed_integration_license_types, 'name').map((ilt, key) => <IntegrationLicenseLink ilt={ilt} key={key} />)}</ul>)
  }
]
function WorkspaceLicenseCard ({ license }) {
  return (
    <Card
      title={license.name}
      className='h-full'
    >
      <FieldListView
        data={license}
        listDefinition={WORKSPACE_FIELDS}
      />
    </Card>
  )
}

function WorkspaceLicenses ({ workspaceLicenses }) {
  if (!workspaceLicenses) return null
  return (
    <div className='grid'>
      {workspaceLicenses.map((license, idx) => (
        <div key={idx} className='col-12 md:col-3 h-full'>
          <WorkspaceLicenseCard license={license} />
        </div>
      ))}
    </div>
  )
}

function FileTypeList ({ fileTypes }) {
  return (
    <ul>
      {_.sortBy(fileTypes, 'label').map((ft, idx) => (
        <li key={idx}>
          {ft.label}
          <div>
            <small>{ft.description}</small>
          </div>
        </li>
      ))}
    </ul>
  )
}

const FLOW_FIELDS = [
  {
    title: 'Flow Type',
    value: 'flow_type'
  },
  {
    title: 'Description',
    value: 'description'
  },
  {
    title: 'Max Flows Allowed',
    value: 'max_flows'
  },
  {
    title: 'Source File Types',
    value: (flow) => <FileTypeList fileTypes={flow.source_file_types} />
  },
  {
    title: 'Destination File Types',
    value: (flow) => <FileTypeList fileTypes={flow.destination_file_types} />
  }
]
function FlowAllowedLink ({ flow }) {
  const [show, setShow] = useState(false)
  return (
    <div className='mt-1'>
      <Button
        link
        size='sm'
        onClick={() => setShow(true)}
      >
        {flow.name}
      </Button>
      <Dialog
        header={`${flow.name} Details`}
        visible={show}
        onHide={() => setShow(false)}
        style={{ width: '80vw' }}
        dismissableMask
      >
        <FieldListView data={flow} listDefinition={FLOW_FIELDS} variant='flush' />
      </Dialog>
    </div>
  )
}

const INTEGRATION_FIELDS = [
  {
    title: 'SKU',
    value: 'sku'
  },
  {
    title: 'Description',
    value: 'description'
  },
  {
    title: 'Price',
    value: (license) => <CurrencyView value={license.price} />
  },
  {
    title: 'Supported By Licenses',
    value: (license) => license.allowed_licenses.join(', ')
  },
  {
    title: 'Transactions Per Unit',
    value: 'transaction_count'
  },
  {
    title: 'Marketing URL',
    value: (license) => license.info_url ? (<ExternalLink href={license.info_url}>Website</ExternalLink>) : null
  },
  {
    title: 'Flows Allowed',
    value: (license) => _.sortBy(license.flows_allowed, 'name').map((fa, idx) => (<FlowAllowedLink flow={fa} key={idx} />))
  }
]
function IntegrationLicenseCard ({ license }) {
  return (
    <Card title={<a name={license.sku}>{license.name}</a>}>
      <FieldListView data={license} listDefinition={INTEGRATION_FIELDS} />
    </Card>
  )
}

function IntegrationLicenses ({ integrationLicenses }) {
  if (!integrationLicenses) return null
  return (
    <div className='grid'>
      {_.sortBy(integrationLicenses, 'name').map((license, idx) => (
        <div key={idx} className='col-12 md:col-4'>
          <IntegrationLicenseCard license={license} />
        </div>
      ))}
    </div>
  )
}

function LicenseTypes () {
  const [workspaceLicenses, setWorkspaceLicenses] = useState(null)
  const [integrationLicenses, setIntegrationLicenses] = useState(null)

  const { data, loading, error } = useQuery(GET_LICENSE_TYPES)

  useEffect(() => {
    if (data) {
      const { workspaceLicenseTypes, integrationLicenseTypes } = data.licenseDefinitions
      setWorkspaceLicenses(workspaceLicenseTypes)
      setIntegrationLicenses(integrationLicenseTypes)
    }
  }, [data])

  if (error) return <ApolloErrorRenderer error={error} />

  if (loading) return <Loading />

  return (
    <div className='p-2'>
      <h1>Workspace Licenses</h1>
      <WorkspaceLicenses workspaceLicenses={workspaceLicenses} />
      <h1>Integration Licenses</h1>
      <IntegrationLicenses integrationLicenses={integrationLicenses} />
    </div>
  )
}

export default LicenseTypes
