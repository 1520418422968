import portalUrl from '../utils/portalUrl'
import ExternalLink from './ExternalLink'

function IntegrationPortalLink ({ companyUUID, tradingPartnerUUID, name }) {
  return (
    <ExternalLink href={portalUrl(`/c/${companyUUID}/tp/${tradingPartnerUUID}`)}>
      {name}
    </ExternalLink>
  )
}

export default IntegrationPortalLink
