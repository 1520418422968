import { gql, useQuery } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'
import ApolloErrorRenderer from '../../components/ApolloErrorRenderer'
import CopyButton from '../../components/CopyButton'
import FieldListView from '../../components/FieldListView'
import Loading from '../../components/Loading'
import SimpleDashboardWidget from '../../components/SimpleDashboardWidget'
import WorkspacePortalLink from '../../components/WorkspacePortalLink'
import InvoicesList from './InvoicesList'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

const GET_WORKSPACE = gql`query getWorkspace($company_uuid: ID!) {
  platformCompany(company_uuid: $company_uuid) {
    company_uuid
    company_name
    created_by
    short_code
    billing_email
    created_date
    updated_date
    updated_by
    users {
      email
      user_role
      first_name
      last_name
      enabled
    }
    sftpUsers {
      username
      relative_root
      disabled
    }
  }
}`

const BASE_LIST_ITEMS = [
  {
    title: 'Created By',
    value: 'created_by',
    clipboard: true
  },
  {
    title: 'Created Date',
    value: 'created_date',
    isDate: true
  },
  {
    title: 'Short Code',
    value: 'short_code',
    clipboard: true
  },
  {
    title: 'Workspace ID',
    value: 'company_uuid',
    clipboard: true
  },
  {
    title: 'Update Date',
    value: 'updated_date',
    isDate: true
  },
  {
    title: 'Updated By',
    value: 'updated_by',
    clipboard: true
  },
  {
    value: (ws) => <WorkspacePortalLink companyUUID={ws.company_uuid} name='View In Portal' />
  }
]

function View () {
  const params = useParams()

  const { loading, error, data } = useQuery(GET_WORKSPACE, { variables: { company_uuid: params.company_uuid } })

  if (error) return <ApolloErrorRenderer error={error} />

  if (loading || !data) return <Loading fullPage />

  const ws = data.platformCompany

  return (
    <div className='p-2'>
      <h1>Workspace: {ws.company_name} <span className='text-xs'><Link to='custom_skus'>Manage Custom SKUs</Link></span></h1>
      <div className='grid'>
        <div className='col-12 md:col-6'>
          <SimpleDashboardWidget>
            <FieldListView data={ws} listDefinition={BASE_LIST_ITEMS} variant='flush' />
          </SimpleDashboardWidget>
          <InvoicesList companyUUID={params.company_uuid} />
        </div>
        <div className='col-12 md:col-6'>
          <SimpleDashboardWidget title='Users'>
            <DataTable
              className='p-datatable-sm'
              emptyMessage='No users found'
              value={ws.users}
            >
              <Column field='email' header='Email' body={user => <><Link to={`/users/${encodeURIComponent(user.email)}`}>{user.email}</Link> <CopyButton text={user.email} /></>} />
              <Column field='first_name' header='First Name' />
              <Column field='last_name' header='Last Name' />
              <Column field='user_role' header='Role' />
              <Column field='enabled' header='Enabled' body={user => user.enabled ? 'Yes' : 'No'} />
            </DataTable>
          </SimpleDashboardWidget>
          <SimpleDashboardWidget title='SFTP Users'>
            <DataTable
              className='p-datatable-sm'
              emptyMessage='No SFTP users found'
              value={ws.sftpUsers}
            >
              <Column field='username' header='Username' />
              <Column field='relative_root' header='Relative Root' />
            </DataTable>
          </SimpleDashboardWidget>
        </div>
      </div>
    </div>
  )
}

export default View
