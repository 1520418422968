import { useState } from 'react'
import useStickyState from '../utils/useStickyState'
import testXML from '../utils/testXML'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Card } from 'primereact/card'

const TestHelp = () => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Button onClick={() => { setOpen(true) }} text>Help</Button>
      <Dialog
        header='Test File Instructions'
        visible={open}
        dismissableMask
        style={{ width: '80vw' }}
        onHide={() => { setOpen(false) }}
      >

        <div>
          <p>
            Your test data should be a valid CSV file with a header row and 4 possible elements on each row.
          </p>
          <ol>
            <li>A valid XPath statement (required)</li>
            <li>A String to match as the text content of the XPath</li>
            <li>A number of times you expect to find this XPath in your file</li>
            <li>Any Match: If you make this element <code>true</code>, the test will pass if ANY XPath result matches the String text.  If it is not included or is set to <code>false</code> then ALL XPaths must match the string.</li>
          </ol>
          <h3>Sample</h3>
          <pre>{`
xpath,value,resultCount,anyMatch
/UniversalShipment/Shipment/DataContext/CodesMappedToTarget,true,1
/UniversalShipment/Shipment/DataContext/ActionPurpose/Code,204,1
/UniversalShipment/Shipment/NoteCollection/Note/Description,"Booking Notes",,true
`}

          </pre>
        </div>
      </Dialog>
    </>
  )
}

const XmlPane = ({ xml, onChange }) => {
  return (
    <>
      <h3>Enter Your XML File Data Here</h3>
      <textarea
        rows={20}
        style={{
          whiteSpace: 'pre',
          overflowWrap: 'normal',
          overflowX: 'scroll',
          width: '100%'
        }}
        onChange={onChange}
        value={xml}
      />
    </>
  )
}

const TestPane = ({ tests, onChange }) => {
  return (
    <>
      <h3>Enter Your Test File Data Here</h3>
      <textarea
        rows={20}
        style={{
          whiteSpace: 'pre',
          overflowWrap: 'normal',
          overflowX: 'scroll',
          width: '100%'
        }}
        onChange={onChange}
        value={tests}
      />
      <TestHelp />
    </>
  )
}

const XmlValidator = () => {
  const [xml, setXml] = useStickyState('', 'xmlvalidator.xml')
  const [tests, setTests] = useStickyState('xpath,value,resultCount,anyMatch', 'xmlValidator.tests')
  const [results, setResults] = useState('')

  const doTest = async () => {
    const res = testXML(xml, tests)
    console.log('results', res)
    setResults(res)
  }

  return (
    <div className='p2'>
      <h1>XML Validator</h1>
      <div>Use this tool to check XML files vs a Chain.io CSV XML Validator</div>
      <div className='grid my-3'>
        <div className='col-6'>
          <XmlPane xml={xml} onChange={evt => setXml(evt.target.value)} />
        </div>
        <div className='col-6'>
          <TestPane tests={tests} onChange={evt => setTests(evt.target.value)} />
        </div>
      </div>
      <Button onClick={doTest}>Run Test</Button>
      {results && (
        <Card
          title='Test Results'
          className='mt-3'
        >
          {results.split('\n').map((val, idx) => (<div className='py-2' style={{ borderBottom: '1px solid gray' }} key={idx}>{val}</div>))}
        </Card>
      )}
    </div>
  )
}

export default XmlValidator
