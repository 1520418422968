import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import CopyButton from './CopyButton'
import { Button } from 'primereact/button'

function SecretView ({ text }) {
  const [show, setShow] = useState(false)

  if (show) {
    return (
      <>
        <code>{text}</code>
        <Button
          link
          title='View Secret'
          className='ml-1'
          style={{
            color: 'lightgray',
            padding: '0.25rem'
          }}
          size='small'
          onClick={() => setShow(false)}
          icon={<FontAwesomeIcon icon={faEyeSlash} />}
        />
        <CopyButton text={text} />
      </>
    )
  }

  return (
    <>
      *****
      <Button
        link
        title='Hide Secret'
        className='ml-1'
        style={{
          color: 'lightgray',
          padding: '0.25rem'
        }}
        size='small'
        onClick={() => setShow(true)}
        icon={<FontAwesomeIcon icon={faEye} />}
      />
      <CopyButton text={text} />
    </>
  )
}

export default SecretView
