import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import isProduction from '../utils/isProduction'
import { Outlet, useNavigate } from 'react-router-dom'

let prefix = 'red-'
let clientId = 'sBNcYNOdJDjMLU6DleBoQu1xKex5p0Zw'

if (isProduction()) {
  clientId = '2P7VVNZDgK6L61uuxsr09lNJDNrJ6efr'
  prefix = ''
}

const domain = 'chainio.auth0.com'
const audience = `https://${prefix}admin-api.chain.io/`

function Auth0Wrapper () {
  const navigate = useNavigate()

  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())
  if (params.error === 'access_denied' && params.error_description) {
    console.error(params.error_description)
    window.alert('There was a problem logging you in, you do not have permission to use this service')
  }
  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience,
        redirect_uri: window.location.origin
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <Outlet />
    </Auth0Provider>
  )
}

export default Auth0Wrapper
