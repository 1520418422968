import { gql, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import ApolloErrorRenderer from '../../components/ApolloErrorRenderer'
import Loading from '../../components/Loading'
import _ from 'lodash'
import DateValue from '../../components/DateValue'
import CSVDownload from '../../components/CSVDownload'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import CopyableUUID from '../../components/CopyableUUID'
import { Link } from 'react-router-dom'
import { InputText } from 'primereact/inputtext'

const CSV_HEADINGS = [
  'Name',
  'UUID',
  'Created'
]

function csvRow (workspace) {
  return [
    workspace.company_name,
    workspace.company_uuid,
    workspace.created_date
  ]
}

const GET_WORKSPACES = gql`query getWorkspaces {
  platformCompanies{
    company_uuid
    company_name
    created_date
  }
}`

function WorkspaceList ({ data }) {
  const [globalFilterValue, setGlobalFilterValue] = useState('')

  if (!data) return <Loading />

  const baseValue = _(data.platformCompanies).orderBy(['company_name', 'company_uuid'])

  const workspaces = baseValue.value()
  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    setGlobalFilterValue(value)
  }
  return (
    <>
      <div className='flex justify-content-between flex-wrap mb-3'>
        <CSVDownload
          fileName='workspaces.csv'
          headings={CSV_HEADINGS}
          recordToStringArrayFn={csvRow}
          records={workspaces}
        />
        <div className='flex justify-content-end'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder='Keyword Search' />
          </span>
        </div>
      </div>
      <DataTable
        value={workspaces}
        stripedRows
        rowHover
        resizableColumns
        size='small'
        sortField='company_name'
        sortOrder={1}
        paginator
        rows={50}
        globalFilter={globalFilterValue}
        dataKey='company_uuid'
        globalFilterFields={['company_name', 'company_uuid']}
      >
        <Column sortable filter header='Name' field='company_name' body={w => <Link to={`/workspaces/${w.company_uuid}`}>{w.company_name}</Link>} />
        <Column sortable filter field='company_uuid' header='UUID' body={w => <CopyableUUID uuid={w.company_uuid} />} />
        <Column sortable field='created_date' header='Created' body={w => <DateValue date={w.created_date} />} />
      </DataTable>
    </>
  )
}

function Workspaces () {
  const { loading, error, data } = useQuery(GET_WORKSPACES)

  if (error) return <ApolloErrorRenderer error={error} />

  if (loading || !data) return <Loading fullPage />

  return (
    <>
      <h1>Workspaces</h1>
      <WorkspaceList data={data} />
    </>
  )
}

export default Workspaces
