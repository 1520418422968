import { stringify } from 'csv-stringify/browser/esm/sync'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { useEffect, useState } from 'react'

function CSVDownloadModal ({ show, setShow, records, recordToStringArrayFn, headings, fileName = 'data.csv' }) {
  const [csvUrl, setCsvUrl] = useState(null)

  useEffect(() => {
    if (show && !csvUrl) {
      const csvContent = [
        headings,
        ...records.map(recordToStringArrayFn)
      ]
      const stringContent = stringify(csvContent)
      // eslint-disable-next-line no-undef
      const csvData = new Blob([stringContent], { type: 'text/csv' })
      setCsvUrl(URL.createObjectURL(csvData))
    }
    if (!show && csvUrl) {
      setCsvUrl(null)
    }
  }, [records, show, recordToStringArrayFn, csvUrl, headings])

  return (
    <Dialog
      visible={show}
      onHide={() => setShow(false)}
      dismissableMask
    >
      Click here to download the CSV file:&nbsp;
      <a href={csvUrl} download={fileName}>
        Download CSV
      </a>
    </Dialog>
  )
}

function CSVDownload ({ records, recordToStringArrayFn, headings, fileName = 'data.csv' }) {
  const [show, setShow] = useState(false)

  return (
    <>
      <Button onClick={() => setShow(true)}>Download CSV</Button>
      <CSVDownloadModal
        show={show}
        setShow={setShow}
        records={records}
        recordToStringArrayFn={recordToStringArrayFn}
        fileName={fileName}
        headings={headings}
      />
    </>
  )
}

export default CSVDownload
