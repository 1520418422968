import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import StandardLayout from '../layouts/StandardLayout'
import Home from '../pages/Home'
import Auth0Wrapper from './Auth0Wrapper'
import ApolloWrapper from './ApolloWrapper'
import TopLevelErrorRenderer from './TopLeveErrorRenderer'
import WorkspacesList from '../pages/workspaces/List'
import WorkspacesView from '../pages/workspaces/View'
import WorkspacesCustomSKUs from '../pages/workspaces/custom_skus/CustomSKUs'
import OpenConnectAdapterList from '../pages/open_connect/List'
import OpenConnectAdapterView from '../pages/open_connect/View'
import FlowList from '../pages/flows/List'
import IntegrationsList from '../pages/integrations/List'
import LicenseTypes from '../pages/LicenseTypes'
import UsersList from '../pages/users/List'
import UsersView from '../pages/users/View'
import EmbeddedExecutionsList from '../pages/embedded_executions/List'
import EmbeddedExecutionsView from '../pages/embedded_executions/View'
import EmbeddedClientList from '../pages/embedded_clients/List'
import SFTPUsersList from '../pages/sftp_users/List'
import XmlValidator from '../pages/XmlValidator'
import CustomerList from '../pages/customers/List'
import CustomerView from '../pages/customers/View'
import GraphQLAccess from '../pages/GraphQLAccess'
import CustomerReview from '../pages/customers/CustomerReview'
import FlowReportList from '../pages/flow_usage/List'
import Links from '../pages/Links'
import FlowLayout from '../pages/FlowLayout'

const router = createBrowserRouter([
  {
    element: <Auth0Wrapper />,
    errorElement: <TopLevelErrorRenderer />,
    children: [
      {
        element: <ApolloWrapper />,
        children: [
          {
            element: <StandardLayout />,
            children: [
              {
                path: '/flow_layout',
                element: <FlowLayout />
              },
              {
                path: '/flow_usage_reports',
                element: <FlowReportList />
              },
              {
                path: '/customers/:saasoptics_id',
                element: <CustomerView />
              },
              {
                path: '/customers/accountreview',
                element: <CustomerReview />
              },
              {
                path: '/customers',
                element: <CustomerList />
              },
              {
                path: '/embedded_clients',
                element: <EmbeddedClientList />
              },
              {
                path: '/embedded_executions/:execution_id',
                element: <EmbeddedExecutionsView />
              },
              {
                path: '/embedded_executions',
                element: <EmbeddedExecutionsList />
              },
              {
                path: '/flows',
                element: <FlowList />
              },
              {
                path: '/integrations',
                element: <IntegrationsList />
              },
              {
                path: '/license_types',
                element: <LicenseTypes />
              },
              {
                path: '/users/:email',
                element: <UsersView />
              },
              {
                path: '/users',
                element: <UsersList />
              },
              {
                path: '/workspaces/:company_uuid/custom_skus',
                element: <WorkspacesCustomSKUs />
              },
              {
                path: '/workspaces/:company_uuid',
                element: <WorkspacesView />
              },
              {
                element: <WorkspacesList />,
                path: '/workspaces'
              },
              {
                element: <OpenConnectAdapterList />,
                path: '/adapters'
              },
              {
                element: <OpenConnectAdapterView />,
                path: '/adapters/:adapter_id'
              },
              {
                element: <XmlValidator />,
                path: '/xml_validator'
              },
              {
                element: <SFTPUsersList />,
                path: '/sftp_users'
              },
              {
                element: <GraphQLAccess />,
                path: '/graphql'
              },
              {
                element: <Links />,
                path: '/links'
              },
              {
                element: <Home />,
                index: true
              }
            ]
          }
        ]
      }
    ]
  }
])

function AppRouter () {
  return <RouterProvider router={router} />
}

export default AppRouter
